export const PENDING = 0;
export const PROCESSING = 1;
export const CONFIRM = 2;
export const ON_THE_WAY = 3;
export const CANCEL = 4;
export const COMPLETE = 5;

export default {
  PENDING,
  PROCESSING,
  CONFIRM,
  ON_THE_WAY,
  CANCEL,
  COMPLETE,
};
