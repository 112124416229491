import axios from "axios";
import config from "../config/server-config";
import { history } from "../utils/navigation-util";

export const authenticate = async (mobile, password) => {
  try {
    let response = await axios.post(config.authenticate, {
      Mobile: mobile,
      Password: password,
    });
    if (response.status === 200) {
      if (response.data.token) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("userEmail", mobile);
        return {
          token: response.data.token,
          userEmail: mobile,
          isValid: true,
        };
      } else {
        return {
          isValid: false,
          message: "Please enter valid username or password.",
        };
      }
    }
  } catch (e) {
    return false;
  }
};

export const userRegister = async (
  firstname,
  middlename,
  lastname,
  mobile,
  password
) => {
  try {
    let response = await axios.post(config.register, {
      FirstName: firstname,
      MiddleName: middlename,
      LastName: lastname,
      Mobile: mobile,
      Password: password,
    });
    if (response.status === 200) {
      return {
        isSuceess: true,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    return false;
  }
};

export const userIsExist = async (mobile) => {
  try {
    let response = await axios.post(config.isUserAvaible, {
      Mobile: mobile,
    });
    if (response.status === 200) {
      return {
        isSuceess: response.data.isUserAvaible,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    return false;
  }
};

export const isUserAuthenticated = () => {
  return window.localStorage.getItem("token");
  // return false;
};

export const sendForgotPasswordEmail = async (email) => {
  axios
    .post(config.sendForgotPasswordEmail, {
      email: email,
      hostName: window.location.host,
    })
    .then(function (res) {
      if (res.status === 200) {
      }
    })
    .catch(function (err) {
      alert("Please try again after some time.");
      return false;
    });
};

export const resetNewPassword = async (password, resetcode, email) => {
  try {
    let response = await axios.post(config.resetPassword, {
      pwdresettoken: resetcode,
      newpassword: password,
      email: email,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const logout = () => {
  window.localStorage.clear();
  history.push("/");
};
