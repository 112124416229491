import { createSlice, createAsyncThunk ,current} from "@reduxjs/toolkit";
import {
  getAllAttributes,
  getAllAttributesSet,
  GetAllAttrValue,
  UpdateAttrValue,
  DeleteAttrValue,
  updateAttribute,
  deleteAttribute,
  deleteAttributeSet,
  getAttributeForIndex,
  updateAttributeShortingIndex,
} from "../../utils/attribute-util";
import AttributeConstant from "../../constants/AttributeConstant";
import { getAllCategories } from "../../utils/category-util";
import { replace } from "connected-react-router";

export const getAllAttributeReq = createAsyncThunk(
  "AllAttributes",
  async (attrId) => {
    const response = await getAllAttributes(attrId);
    return { attrId, response };
  }
);

export const updateAttributeReq = createAsyncThunk(
  "updateAttributeReq",
  async (req) => {
    var listCatId = [];

    req.catIds.forEach((e) => {
      listCatId.push(e.value);
    });

    let aSetId = 0;
    if (req.attrSetId !== undefined) {
      aSetId = req.attrSetId.value;
    }

    const data = {
      attrId: req.attrId,
      attrSetId: aSetId,
      name: req.name,
      catIds: listCatId.toString(),
      url: req.url,
      isSearchable: req.isFilterable,
    };

    const response = await updateAttribute(data);
    return response;
  }
);

export const getAllAttributeValueReq = createAsyncThunk(
  "getAllAttributeValueReq",
  async (attrId) => {
    const response = await GetAllAttrValue(attrId);
    return response;
  }
);

export const attributeValueUpdateReq = createAsyncThunk(
  "attributeValueUpdateReq",
  async (req) => {
    const response = await UpdateAttrValue(req);
    return response;
  }
);

export const deleteAttributeValReq = createAsyncThunk(
  "deleteAttributeValReq",
  async (attrId) => {
    const response = await DeleteAttrValue(attrId);
    return response;
  }
);

export const getAllAttributeSetReq = createAsyncThunk(
  "AllAttributesSet",
  async (attrSetId) => {
    const response = await getAllAttributesSet(attrSetId);
    return { attrSetId, response };
  }
);

export const getAllCategoryReq = createAsyncThunk(
  "getAllCategoryReq",
  async (catid) => {
    const response = await getAllCategories(catid);
    return { catid, response };
  }
);

export const disableValueReq = createAsyncThunk(
  "disableValueReq",
  async (req) => {
    return req;
  }
);

export const getAttributeForIndexReq = createAsyncThunk(
  "getAttributeForIndexReq",
  async (catid) => {
    const response = await getAttributeForIndex(catid);
    return response;
  }
);

export const updateAttributeShortingIndexReq = createAsyncThunk(
  "updateAttributeShortingIndexReq",
  async (attrid, index) => {
    const response = await updateAttributeShortingIndex(attrid, index);
    return response;
  }
);

// export const updateBrandReq = createAsyncThunk(
//   "updateBrandReq",
//   async (req) => {
//     const response = await UpdateBrand(req);
//     return response;
//   }
// );

const AttributesSlice = createSlice({
  name: "attribute",
  initialState: {
    allAttributes: [],
    allAttributeSet: [],
    allCategories: [],
    allParentCategories: [],
    parentCatSelection: [],
    lstAllAttrForIndex: [],

    selectedAttributeId: 0,
    attributeSetSelection: [],
    attrCategorySelection: [],
    allAttributeValue: [],

    generalTabSelected: true,
    valueTabSelected: false,
    txtAttributeName: "",
    txtAttrUrl: "",
    isFilterable: false,

    disableValueTab: true,
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        state.valueTabSelected = false;
        switch (action.payload) {
          case AttributeConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          case AttributeConstant.VALUE:
            state.valueTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtAttributeName: {
      reducer(state, action) {
        state.txtAttributeName = action.payload;
      },
      prepare(e) {
        return {
          // payload: e.target.value.replace(/[^a-zA-Z ]/g, ""),
          payload: e.target.value,
        };
      },
    },
    handleTxtAttrUrl: {
      reducer(state, action) {
        state.txtAttrUrl = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleAttributeSetSelectionChange: {
      reducer(state, action) {
        state.attributeSetSelection = action.payload.attributeSetSelection;
      },
      prepare(attributeSetSelection) {
        return {
          payload: { attributeSetSelection },
        };
      },
    },

    handleOnChangeParentCat: {
      reducer(state, action) {
        state.parentCatSelection = action.payload.parentCat;
      },
      prepare(parentCat) {
        return {
          payload: { parentCat },
        };
      },
    },
    handleCategorySelectionChange: {
      reducer(state, action) {
        let myJson = [];
        action.payload.attrCategorySelection.forEach((e) => {
          myJson.push({ value: e.value, label: e.label.split("|--").join("") });
        });

        state.attrCategorySelection = myJson;
      },
      prepare(attrCategorySelection) {
        return {
          payload: { attrCategorySelection },
        };
      },
    },
    handleIsFilterable: {
      reducer(state, action) {
        if (state.isFilterable) {
          state.isFilterable = false;
        } else {
          state.isFilterable = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    // handleDisableValue: {
    //   reducer(state, action) {
    //     state.disableValueTab = false;
    //   },
    //   prepare() {
    //     return {
    //       payload: {},
    //     };
    //   },
    // },
    handleResetAttribute: {
      reducer(state, action) {
        state.selectedAttributeId = 0;
        state.attributeSetSelection = [];
        state.attrCategorySelection = [];

        state.generalTabSelected = true;
        state.valueTabSelected = false;
        state.txtAttributeName = "";
        state.txtAttrUrl = "";
        state.isFilterable = false;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleDeleteAttribute: {
      reducer(state, action) {},
      prepare(e) {
        deleteAttribute(e);
        return {
          payload: e,
        };
      },
    },
    handleAttrShortingIndex: {
      reducer(state, action) {
        let s = action.payload.e;
        let aid = action.payload.attrid;
        let prvAttrShorting = current(state).lstAllAttrForIndex;
        let newJson = [];
        prvAttrShorting.forEach((s) => {
          if (s.attrId === aid) {
            newJson.push({ ...s, shortingIndex: parseInt(action.payload.e.target.value)  });
          } else {
            newJson.push(s);
          }
        });
        state.lstAllAttrForIndex = newJson;
      },
      prepare(e, attrid) {
        return {
          payload: { e, attrid },
        };
      },
    },
  },
  extraReducers: {
    [getAllAttributeReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.attrId === 0) {
          state.allAttributes = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedAttributeId = action.payload.response[0].attrId;
            state.disableValueTab = false;

            if (action.payload.response[0].attrSetsId !== null) {
              state.attributeSetSelection = JSON.parse(
                action.payload.response[0].attrSetsId
              )[0];
            } else {
              state.attributeSetSelection = [];
            }

            if (action.payload.response[0].catId !== null) {
              state.attrCategorySelection = JSON.parse(
                action.payload.response[0].catId
              );
            } else {
              state.attrCategorySelection = [];
            }

            state.isFilterable = action.payload.response[0].isSearchable;
            state.txtAttributeName = action.payload.response[0].attrName;
            state.txtAttrUrl = action.payload.response[0].url;

            // state.selectedBrandId = action.payload.response[0].brandId;
            // state.txtBrandName = action.payload.response[0].brandName;
            // state.IsEnableBrand = action.payload.response[0].isActive;
            // state.brandImgPath = action.payload.response[0].brandImgUrl;
            // state.brandBannerPath = action.payload.response[0].bannerUrl;
            // state.txtSeoUrl = action.payload.response[0].seoUrl;
            // state.txtMetaTitle = action.payload.response[0].metaTitle;
            // state.txtMetaDesc = action.payload.response[0].metaDesc;
          }
        }
      }
    },
    [updateAttributeReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.selectedAttributeId = action.payload;
      }
    },
    [getAllAttributeSetReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.response.forEach((e) => {
          myJson.push({ value: e.attrSetsId, label: e.attrSetsName });
        });
        state.allAttributeSet = myJson;
      }
    },
    [disableValueReq.fulfilled]: (state, action) => {
      state.disableValueTab = action.payload;
    },
    [getAllAttributeValueReq.fulfilled]: (state, action) => {
      state.allAttributeValue = action.payload;
    },
    [attributeValueUpdateReq.fulfilled]: (state, action) => {
      // state.allAttributeValue = action.payload;
    },
    [deleteAttributeValReq.fulfilled]: (state, action) => {
      // state.allAttributeValue = action.payload;
    },
    [getAllCategoryReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        // start filter parent cat
        let parentOnlyData = action.payload.response.filter(
          (c) => c.parentId === 0 && c.isActive
        );
        let myJson_parentCatData = [];
        parentOnlyData.forEach((e) => {
          myJson_parentCatData.push({ label: e.name, value: e.catId });
        });
        state.allParentCategories = myJson_parentCatData;
        // End filter parent cat

        let myJson = [];
        let count = 0;
        let labelPrefix = "";
        const getIndex = (catid) => {
          count = count + 1;
          labelPrefix = labelPrefix + "|-- ";
          if (catid !== undefined) {
            let lstParent = action.payload.response.filter(
              (c) => c.catId == catid
            );
            if (lstParent.length > 0) {
              let pid = lstParent[0].parentId;
              if (pid !== 0) {
                getIndex(pid);
              }
            }
          }
          return count;
        };

        action.payload.response.forEach((e) => {
          if (e.parentId !== 0) {
            count = 0;
            labelPrefix = "";
            let parentid = getIndex(e.parentId);
          }
          myJson.push({ value: e.catId, label: labelPrefix + e.name });
        });
        state.allCategories = myJson;
      }
    },
    [getAttributeForIndexReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        state.lstAllAttrForIndex = action.payload;
      }
    },
  },
});

export const {
  handleTabSelection,
  //   handleIsEnableBrand,
  handleTxtAttributeName,
  handleDeleteAttribute,
  handleResetAttribute,
  handleAttributeSetSelectionChange,
  handleCategorySelectionChange,
  handleTxtAttrUrl,
  handleIsFilterable,
  handleOnChangeParentCat,
  handleAttrShortingIndex,
} = AttributesSlice.actions;

export default AttributesSlice.reducer;
