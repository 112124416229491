import axios from "axios";
import config from "../config/server-config";

export const getAllTags = async (tagid) => {
  try {
    let response = await axios.post(config.getAllTags, {
      TagId: parseInt(tagid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getTags = async (tagid) => {
  try {
    let response = await axios.post(config.getTags, {
      TagId: parseInt(tagid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const TagDelete = async (tagid) => {
  try {
    let response = await axios.post(config.deleteTag, {
      TagId: parseInt(tagid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateTag = async (tagid, tagname, tagurl) => {
  try {
    let response = await axios.post(config.updateTag, {
      TagId: tagid,
      TagName: tagname,
      TagUrl: tagurl,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
