import React from "react";
import PropTypes from "../utils/propTypes";
import pathConstant from "../constants/pathConstant";
import bn from "../utils/bemnames";
const bem = bn.create("page");

const Page = ({ title, breadcrumbs, tag: Tag, className, children, ...restProps }) => {
  const classes = bem.b("px-3", className);

  return (
    <Tag className={classes} {...restProps}>
      <div className={bem.e("header")}>
        {title && typeof title === "string" ? <h1 className={bem.e("title")}>{title}</h1> : title}
        {breadcrumbs && (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={pathConstant.ADMIN_DASHBOARD}>Dashboard</a>
              </li>
              {breadcrumbs.length &&
                breadcrumbs.map(({ name, active, isLink, url }, index) =>
                  isLink === true ? (
                    <li className="breadcrumb-item active" key={index} active={active} aria-current="page">
                      <a href={url}>{name}</a>
                    </li>
                  ) : (
                    <li className="breadcrumb-item active" key={index} active={active} aria-current="page">
                      {name}
                    </li>
                  )
                )}
            </ol>
          </nav>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.string,
    })
  ),
};

Page.defaultProps = {
  tag: "div",
  title: "",
};

export default Page;
