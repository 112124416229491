export const GENERAL = "General";
export const PRICE = "Price";
export const INVENTORY = "Inventory";
export const IMAGES = "Images";
export const DOWNLOAD = "Download";
export const SEO = "SEO";

export const ATTRIBUTES = "Attributes";
export const OPTIONS = "Options";
export const RELATED_PRODUCTS = "Related Products";
export const UP_SELLS = "Up-Sells";
export const CROSS_SELLS = "Cross-Sells";
export const ADDITIONAL = "Additional";

export default {
  GENERAL,
  PRICE,
  INVENTORY,
  IMAGES,
  DOWNLOAD,
  SEO,

  ATTRIBUTES,
  OPTIONS,
  RELATED_PRODUCTS,
  UP_SELLS,
  CROSS_SELLS,
  ADDITIONAL,
};
