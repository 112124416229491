import axios from "axios";
import config from "../config/server-config";

export const getAllCategories = async (catid) => {
  try {
    let response = await axios.post(config.getAllCategory, {
      CatId: parseInt(catid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateCategory = async (req) => {
  console.log("reqreqreq", req);
  try {
    let response = await axios.post(config.updateCategory, {
      CatId: req.catid,
      ParentId: req.parentid,
      Name: req.name,
      IsSearchable: req.searchable,
      IsActive: req.isActive,
      Logo: req.logo,
      Banner: req.banner,
      SeoUrl: req.seourl,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteCategory = async (catid) => {
  try {
    let response = await axios.post(config.deleteCategory, {
      CatId: parseInt(catid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
