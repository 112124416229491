import axios from "axios";
import config from "../config/server-config";
import { TokenDecode } from "./general-util";

export const getAllUsers = async (userid) => {
  try {
    let response = await axios.post(config.getAllUsers, {
      UserId: parseInt(userid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCountries = async () => {
  try {
    let response = await axios.post(config.getAllCountries, {});
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetStates = async (countryid) => {
  try {
    let response = await axios.post(config.getAllStates, {
      CountryId: parseInt(countryid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetCities = async (stateid) => {
  try {
    let response = await axios.post(config.getAllCities, {
      StateId: parseInt(stateid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAllAddress = async () => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.getAllAddress, {
      UserId: parseInt(uid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateAddress = async (res) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.updateAddress, {
      AddressId: res.addressid,
      UserId: parseInt(uid),
      FullName: res.fullname,
      Address: res.adddress,
      Pincode: res.pincode,
      Mobile: res.mobile,
      IsDefault: res.isDefault,
      CityId: res.cityid,
      StateId: res.stateid,
      CountryId: res.countryid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateUserDetails = async (res) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.updateUserDetails, {
      UserId: parseInt(uid),
      FirstName: res.firstname,
      MiddleName: res.middlename,
      LastName: res.lastname,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const DeleteUserAddress = async (addressid) => {
  try {
    let response = await axios.post(config.deleteAddress, {
      AddressId: addressid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
