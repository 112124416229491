import React, { useState, useEffect } from "react";
import EazyCrop from "../../GeneralForm/EasyCrop/EasyCrop";
import { GetAllImages } from "../../../utils/general-util";
import pathConstant from "../../../constants/pathConstant";

const FileUploader = ({ handleCloaseModelPopup, imagePath, size = "4 / 3", imageType = 0 }) => {
  const [img, setImg] = useState("/images/Product.jpg");
  const [imglst, setImglst] = useState([]);
  const [cloneLst, setCloneList] = useState([]);

  useEffect(() => {
    GetAllImages(imageType)
      .then((res) => {
        const myJson = [];
        res.forEach((e) => {
          setCloneList(res);
          if (myJson.filter((x) => x.imgPath === e.imgPath).length === 0) {
            myJson.push(e);
          }
        });
        setImglst(myJson);
      })
      .catch((e) => console.log(e));
  }, []);

  const handleImageSerch = (e) => {
    setImglst(cloneLst.filter((item) => item.name.indexOf(e.target.value) !== -1));
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCropImg = (e) => {
    imagePath(e);
    handleCloaseModelPopup(false);
  };

  return (
    <div className="row">
      <div className="col-6 blockUploader">
        <input type="file" accept="image/*" onChange={onSelectFile} />
        <EazyCrop imgSize={size} dogImg={img} handleCropImg={handleCropImg} />
      </div>
      <div className="col-6">
        <div className="row imgSelectionBlock">
          <input type="text" className="form-control" placeholder="Search..." onChange={handleImageSerch} />
        </div>
        {imglst.length > 0 && (
          <div className="row imgSelectionBlock scrollbar">
            {imglst.map((c, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    handleCropImg(c.imgPath);
                  }}
                  className="col-4"
                >
                  <img
                    src={c.imgPath}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${pathConstant.PRODUCT_IMG_PATH}`;
                    }}
                    alt="prodImg"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
