import axios from "axios";
import { parse } from "path";
import config from "../config/server-config";

export const getAllOptions = async (optionid) => {
  try {
    let response = await axios.post(config.getAllOptions, {
      OptionId: parseInt(optionid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getAllOptionValues = async (optionid) => {
  try {
    let response = await axios.post(config.getAllOptionValue, {
      OptionId: parseInt(optionid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const DeleteOptions = async (optionid) => {
  try {
    let response = await axios.post(config.deleteOptions, {
      OptionId: optionid,
    });
    if (response.status === 200) {
      return {
        isSuceess: true,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const updateOptions = async (optionid, name, typeid, isrequired, productid) => {
  try {
    let response = await axios.post(config.updateOptions, {
      OptionId: optionid,
      OptionName: name,
      TypeId: typeid,
      ProductId: productid,
      IsRequired: isrequired,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const addOptionValue = async (optionid, label, price, pricetype) => {
  try {
    let response = await axios.post(config.addOptionValueReq, {
      OptionValueId: 0,
      OptionId: parseInt(optionid),
      Label: label,
      Price: price,
      PriceType: parseInt(pricetype),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteOptionValue = async (attrId) => {
  try {
    let response = await axios.post(config.deleteOptionValue, {
      OptionValueId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
