import React, { useState } from "react";
import bn from "../../utils/bemnames";
import { logout } from "../../utils/login-util";
import { Link } from "react-router-dom";
import pathConstant, { LOGIN } from "../../constants/pathConstant";
import config from "../../config/server-config";
import { history } from "../../utils/navigation-util";
import HeaderSearch from "./Component/HeaderSearch";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { USER_CLIENT_ORDER, USER_CLIENT_WISHLIST, USER_CLIENT_PROFILE, MYCART } from "../../constants/pathConstant";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import jwt from "jsonwebtoken";
const bem = bn.create("clientHeader");

const ClientHeader = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div className={bem.b("first-head")}>
        <div className="container">
          <div className="f-head">
            <div className="time">
              <i className="fa fa-clock-o" /> &nbsp;&nbsp;Mon-Fri 09:00 AM - 08:00 PM
            </div>
            <div className="call">
              <svg width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>{" "}
              &nbsp;&nbsp;+91 85 11 60 50 50
            </div>
          </div>
        </div>
      </div>
      <div className={bem.b("navigation sticky-top")}>
        <div className="container sticky-top">
          <div className="row sticky-top">
            <div className="col-md-2 col-sm-4">
              <div className="logo">
                <a href="/">
                  <img src="images/logo.png" className="logo-img img-fluid" alt="logo-img" />
                </a>
              </div>
            </div>
            <div className="col-md-8 col-sm-4 text-center">
              <div className="nav">
                <nav className="navbar navbar-expand-lg">
                  <button
                    className="navbar-toggler mr-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fas fa-bars" style={{ color: "#000", fontSize: 28 }} />
                    </span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <li className="dash">|</li>
                      <li className="nav-item">
                        <a className="nav-link" href="/product">
                          Product
                        </a>
                      </li>
                      <li className="dash">|</li>
                      <li className="nav-item">
                        <a className="nav-link" href="/Services">
                          Services
                        </a>
                      </li>
                      <li className="dash">|</li>
                      <li className="nav-item">
                        <a className="nav-link" href="/Projects">
                          Projects
                        </a>
                      </li>
                      <li className="dash">|</li>
                      <li className="nav-item">
                        <a className="nav-link" href="/AboutUs">
                          About Us
                        </a>
                      </li>
                      <li className="dash">|</li>

                      <li className="nav-item">
                        <a className="nav-link" href="/Contact">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  <br />
                </nav>
                <HeaderSearch />
              </div>
            </div>
            <div className="col-md-2 col-sm-4">
              <div className="user-link">
                <div className="login">
                  {props.userMainDetails === undefined ? (
                    <a href="/Login">
                      {/* <img src="images/user-1.png" className="mycart-img" alt="cart" /> */}
                      <i class="bi bi-person-circle home-icon"></i>
                      <br />
                      <span className="hid">Login</span>
                    </a>
                  ) : (
                    <div>
                      {/* <a href="/Login" onClick={() => logout()}>
                        <img src="images/user-1.png" className="mycart-img" alt="cart" />
                        <br />
                        <span className="hid">Logout</span>
                      </a> */}
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          {/* <img src="images/user-1.png" className="mycart-img" alt="cart" /> */}
                          <i class="bi bi-person-circle home-icon"></i>
                          <br />
                          <span className="hid">Profile</span>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              history.push(USER_CLIENT_PROFILE);
                            }}
                          >
                            Profile
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              history.push(USER_CLIENT_ORDER);
                            }}
                          >
                            My Order
                          </DropdownItem>
                          <DropdownItem divider className="m-0" />
                          <DropdownItem
                            onClick={() => {
                              logout();
                              props.handleUpdateIsLoginStatus(false);
                              history.push("/Login");
                            }}
                          >
                            {props.isLogin ? "Log Out" : "Log In"}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
                </div>
                <div className="mycart">
                  {props.userMainDetails === undefined ? (
                    <a
                      onClick={() => {
                        history.push("/Login");
                      }}
                    >
                      <i className="bi bi-heart home-icon"></i>
                      {/* <img src="images/" className="mycart-img" alt="cart" /> */}
                      <br />
                      <span className="hid">Wishlist</span>
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        history.push(USER_CLIENT_WISHLIST);
                      }}
                    >
                      <i className="bi bi-heart home-icon"></i>
                      {/* <img src="images/" className="mycart-img" alt="cart" /> */}
                      <br />
                      <span className="hid">Wishlist</span>
                    </a>
                  )}
                </div>
                <div className="mycart">
                  <a
                    onClick={() => {
                      history.push(pathConstant.MYCART);
                    }}
                  >
                    {/* <img src="images/cart.png" className="mycart-img" alt="cart" /> */}
                    <i class="bi bi-cart3 home-icon"></i>
                    <br />
                    <span className="hid">My Cart</span>
                    {/* <span className="counter">{props.cartItems.length}</span> */}
                    <span className="counter">
                      {JSON.parse(sessionStorage.getItem("cartItems")) === null
                        ? 0
                        : JSON.parse(sessionStorage.getItem("cartItems")).length}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className="navigation1">
        <div className="mobile-menu">
          <div>
            <Navbar color="light" light expand="md" className="p-0 m-2">
              <NavbarBrand href="/">
                <img
                  src="images/logo/logo2.png"
                  style={{ maxWidth: "45px", padding: "5px" }}
                  className="logo-img img-fluid"
                  alt="logo-img"
                />
                Vasani Electricals
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink href="/">Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/product">Product</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/Services">Services</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/Projects">Projects</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/AboutUs">About Us</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/Contact">Contact US</NavLink>
                  </NavItem>
                  {props.isLogin ? (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Items
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => {
                            history.push(USER_CLIENT_PROFILE);
                          }}
                        >
                          Profile
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            history.push(USER_CLIENT_WISHLIST);
                          }}
                        >
                          Wish List
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            history.push(MYCART);
                          }}
                        >
                          Cart Items
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            history.push(USER_CLIENT_ORDER);
                          }}
                        >
                          Orders
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            logout();
                            props.handleUpdateIsLoginStatus(false);
                          }}
                        >
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (
                    <NavItem>
                      <NavLink href="/Login">Login</NavLink>
                    </NavItem>
                  )}
                </Nav>
              </Collapse>
            </Navbar>
          </div>
          <div className="search-bar pb-2">
            <div className="catagories">
              <div>
                {/* <form className="form-inline ml-auto">
                  <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                  <button className="serch-btn" type="submit">
                    <i className="bi bi-search" aria-hidden="true" />
                  </button>
                </form> */}
                <HeaderSearch />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientHeader;
