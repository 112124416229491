import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { UpdateTaxClass } from "../../utils/product-util";
import CatalogConstant from "../../constants/CatalogConstant";
import pathConstant from "../../constants/pathConstant";
var dateFormat = require("dateformat");

export const updateTaxClassReq = createAsyncThunk("updateTaxClassReq", async (req) => {
  const response = await UpdateTaxClass(req);
  return response;
});

const TaxClassSlice = createSlice({
  name: "taxClass",
  initialState: {
    taxName: "",
    taxPercentage: 0,
    taxClassId: 0,
  },
  reducers: {
    handleTaxClassReset: {
      reducer(state, action) {
        state.taxClassId = 0;
        state.taxPercentage = 0;
        state.taxName = "";
      },
      prepare() {
        return {
          payload: null,
        };
      },
    },
    handleOnChangeTaxName: {
      reducer(state, action) {
        state.taxName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeTaxPercentage: {
      reducer(state, action) {
        state.taxPercentage = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    hanleEditTaxClass: {
      reducer(state, action) {
        if (action.payload && action.payload.length > 0) {
          const val = action.payload[0];
          state.taxClassId = val.value;
          state.taxPercentage = val.taxPercentage;
          state.taxName = val.label;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [updateTaxClassReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        // state.taxClass = action.payload;
      }
    },
  },
});

export const { handleOnChangeTaxPercentage, handleTaxClassReset, handleOnChangeTaxName, hanleEditTaxClass } =
  TaxClassSlice.actions;

export default TaxClassSlice.reducer;
