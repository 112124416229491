import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  GetAllProductByCat,
  GetMasterSearch,
  GetAllFilterAttr,
  GetFilterAttributeValues,
} from "../../utils/product-util";
import { getAllCategories } from "../../utils/category-util";
import getAllProductByCatConstant from "../../constants/GetAllProductByCatConstant";

export const getMasterSearchReq = createAsyncThunk("getMasterSearchReq", async (req) => {
  const response = await GetMasterSearch(req);
  return response;
});

export const getAllProductByCatReq = createAsyncThunk("getAllProductByCatReq", async (req) => {
  const response = await GetAllProductByCat(req.catid, req.productid);
  return { response, type: req.catid };
});

export const getAllActiveCategoryReq = createAsyncThunk("getAllActiveCategoryReq", async () => {
  const response = await getAllCategories(0);
  return response;
});

export const getSearchProductReq = createAsyncThunk("getSearchProductReq", async () => {
  const response = await getAllCategories(0);
  return response;
});

export const getAllFilterAttrReq = createAsyncThunk("getAllFilterAttrReq", async (categoryid) => {
  const response = await GetAllFilterAttr(categoryid);
  return response;
});

export const getFilterAttributeValuesReq = createAsyncThunk("getFilterAttributeValuesReq", async (categoryid) => {
  const response = await GetFilterAttributeValues(categoryid);
  return response;
});

const ProductSlice = createSlice({
  name: "product",
  initialState: {
    lstLatestProduct: [],
    lstRelatedProduct: [],
    lstAllCategories: [],
    lstProductSearchResult: [],
    isProductSearchLoading: true,
    currentPageNo: 1,
    totalResult: 0,
    pagesize: 12,
    totalPages: 0,
    priceStart: 0,
    priceEnd: 500000,
    searchText: "",
    categoryIds: "",
    tags: "",
    attrValueIds: [],
    brandSelectionIds: [],
    brandSearch: "",
    lstFilterAttr: [],
    lstFilterAttrValues: [],
    arrayFilterOption: [],
    arrayFilterOptionBakup: [],
  },
  reducers: {
    handleSetCategoryId: {
      reducer(state, action) {
        state.categoryIds = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOnBrandSelectionChecked: {
      reducer(state, action) {
        let myBrandSelection = [];
        let prevBrandSelection = current(state).brandSelectionIds;
        if (action.payload.ele.target.checked) {
          myBrandSelection.push(action.payload.brandid);
          myBrandSelection = myBrandSelection.concat(prevBrandSelection);
        } else {
          myBrandSelection = prevBrandSelection.filter((c) => c !== action.payload.brandid);
        }
        state.brandSelectionIds = myBrandSelection;
      },
      prepare(ele, brandid) {
        return {
          payload: { ele, brandid },
        };
      },
    },
    handleOnAttrValueChecked: {
      reducer(state, action) {
        let myAttrValueSelection = [];
        let preAttrValueSelection = current(state).attrValueIds;
        if (action.payload.ele.target.checked) {
          myAttrValueSelection.push(action.payload.attrValueId);
          myAttrValueSelection = myAttrValueSelection.concat(preAttrValueSelection);
        } else {
          myAttrValueSelection = preAttrValueSelection.filter((c) => c !== action.payload.attrValueId);
        }
        state.attrValueIds = myAttrValueSelection;
      },
      prepare(ele, attrValueId) {
        return {
          payload: { ele, attrValueId },
        };
      },
    },
    handleResetIsLoadingSearch: {
      reducer(state, action) {
        state.isProductSearchLoading = action.payload;
      },
      prepare(ele) {
        return {
          payload: ele,
        };
      },
    },
    handleSetCurrentPage: {
      reducer(state, action) {
        state.currentPageNo = action.payload;
      },
      prepare(cPage) {
        return {
          payload: cPage,
        };
      },
    },
    handleOnChangefilterAttrSelection: {
      reducer(state, action) {
        let currentFilterOption = current(state).arrayFilterOptionBakup;
        let myJson = [];
        currentFilterOption.forEach((e) => {
          if (e.optionAttrId === action.payload.attrid) {
            let fOption = e.options.filter(
              (c) => c.attValues.toLowerCase().indexOf(action.payload.ele.target.value.toLowerCase()) !== -1
            );
            myJson.push({ ...e, optionfilterText: action.payload.ele.target.value, options: fOption });
          } else {
            myJson.push(e);
          }
        });
        state.arrayFilterOption = myJson;
      },
      prepare(ele, attrid) {
        return {
          payload: { ele, attrid },
        };
      },
    },
    handleBrandSearch: {
      reducer(state, action) {
        state.brandSearch = action.payload;
      },
      prepare(ele) {
        return {
          payload: ele.target.value,
        };
      },
    },
    handleOnPriceChange: {
      reducer(state, action) {
        state.priceStart = action.payload.start;
        state.priceEnd = action.payload.end;
      },
      prepare(start, end) {
        return {
          payload: { start, end },
        };
      },
    },
  },
  extraReducers: {
    [getAllProductByCatReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload !== false) {
        if (action.payload.type === getAllProductByCatConstant.ALL_PRODUCT_BY_CATEGORY_RELATED) {
          state.lstRelatedProduct = action.payload.response;
        }
        if (action.payload.type === getAllProductByCatConstant.ALL_PRODUCT_BY_CATEGORY_LATEST) {
          state.lstLatestProduct = action.payload.response;
        }
      }
    },
    [getAllActiveCategoryReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.lstAllCategories = action.payload;
      }
    },
    [getSearchProductReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.lstAllCategories = action.payload;
      }
    },
    [getMasterSearchReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.isProductSearchLoading = false;
        state.lstProductSearchResult = action.payload;
        if (action.payload.length > 0) {
          let pSize = current(state).pagesize;

          state.totalResult = action.payload[0].totalProduct;
          state.totalPages = Math.ceil(action.payload[0].totalProduct / pSize);
        } else {
          state.totalPages = 0;
        }
      }
    },
    [getAllFilterAttrReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.lstFilterAttr = action.payload;
      }
    },
    [getFilterAttributeValuesReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.lstFilterAttrValues = action.payload;
        let lstFilterAttr = current(state).lstFilterAttr;
        let myJson = [];
        lstFilterAttr.forEach((e) => {
          let fOption = action.payload.filter((c) => c.attrId === e.attrId);
          if (fOption.length > 0) {
            myJson.push({ optionName: e.attrName, optionfilterText: "", optionAttrId: e.attrId, options: fOption });
          }
        });

        state.arrayFilterOption = myJson;
        state.arrayFilterOptionBakup = myJson;
      }
    },
  },
});

export const {
  handleSetCategoryId,
  handleOnPriceChange,
  handleOnBrandSelectionChecked,
  handleResetIsLoadingSearch,
  handleBrandSearch,
  handleOnAttrValueChecked,
  handleOnChangefilterAttrSelection,
  handleSetCurrentPage,
} = ProductSlice.actions;

export default ProductSlice.reducer;
