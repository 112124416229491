import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { TokenDecode } from "../../utils/general-util";
// import Banner from "./Banner.json";
// import Banner from "./Banner2.json";
import Banner from "./Banner_static.json";

export const decodeTokenReq = createAsyncThunk("login", async (arg) => {
  const response = await TokenDecode();
  return response;
});

const MainSlice = createSlice({
  name: "clientMain",
  initialState: {
    isLogin: false,
    cartItems: [],
    subTotal: 0,
    productGST: 0,
    total: 0,
    userMainDetails: {},
    homeBanner: Banner,
    topProducts: [
      {
        productid: 1,
        imgUrl: "images/pro2.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro4.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro1.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro3.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro2.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro4.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro1.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
      {
        productid: 1,
        imgUrl: "images/pro3.png",
        name: "Sometime in Purpose",
        mrp: 360.66,
      },
    ],
    productBanners: [
      {
        imgUrl: "images/prod-offer-1.jpg",
      },
      {
        imgUrl: "images/prod-offer-1.jpg",
      },
      {
        imgUrl: "images/prod-offer-1.jpg",
      },
      {
        imgUrl: "images/prod-offer-1.jpg",
      },
    ],
  },
  reducers: {
    handleMyCartItems: {
      reducer(state, action) {
        let cItem = [];
        if (JSON.parse(sessionStorage.getItem("cartItems")) !== null) {
          let previousSelection = JSON.parse(sessionStorage.getItem("cartItems")); // current(state).cartItems;
          previousSelection.forEach((e) => {
            cItem.push(e);
          });
        }

        if (cItem.filter((c) => c.productid === action.payload.productid).length === 0) {
          cItem.push({
            productid: action.payload.productid,
            img: action.payload.imgUrl,
            qty: 1,
            mrp: action.payload.mrp,
            name: action.payload.name,
          });
        }
        state.cartItems = cItem;
        state.subTotal = cItem.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0);
        state.productGST = (cItem.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0) * 18) / 100;
        // state.total = state.subTotal + state.productGST;
        state.total = Math.round((state.subTotal + state.productGST + Number.EPSILON) * 100) / 100;
        sessionStorage.setItem("cartItems", JSON.stringify(cItem));
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleUpdateCartItems: {
      reducer(state, action) {
        let lstCartItem = current(state).cartItems;
        let updateCartJson = [];
        lstCartItem.forEach((e) => {
          if (e.qty !== 1 || action.payload.action === 1) {
            if (action.payload.productid === e.productid && action.payload.action === 1) {
              updateCartJson.push({ ...e, qty: e.qty + 1 });
            } else if (action.payload.productid === e.productid && action.payload.action === 0) {
              updateCartJson.push({ ...e, qty: e.qty - 1 });
            } else {
              updateCartJson.push(e);
            }
          } else {
            updateCartJson.push(e);
          }
        });
        state.cartItems = updateCartJson;
        state.subTotal = updateCartJson.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0);
        state.productGST = (updateCartJson.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0) * 18) / 100;
        // state.total = state.subTotal + state.productGST;
        state.total = state.subTotal + state.productGST;
        sessionStorage.setItem("cartItems", JSON.stringify(updateCartJson));
      },
      prepare(productid, action) {
        return {
          payload: { productid, action },
        };
      },
    },
    handleRemoveItemFromCart: {
      reducer(state, action) {
        let lstCartItem = current(state).cartItems;

        let filterCartItem = lstCartItem.filter((c) => c.productid !== action.payload.productid);

        state.subTotal = filterCartItem.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0);
        state.productGST = (filterCartItem.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0) * 18) / 100;
        // state.total = state.subTotal + state.productGST;
        state.total =Math.round((state.subTotal + state.productGST + Number.EPSILON) * 100) / 100; 

        state.cartItems = filterCartItem;
        sessionStorage.setItem("cartItems", JSON.stringify(filterCartItem));
      },
      prepare(productid) {
        return {
          payload: { productid },
        };
      },
    },
    handleGetCartItems: {
      reducer(state, action) {
        if (JSON.parse(sessionStorage.getItem("cartItems")) !== null) {
          state.cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
          state.subTotal = state.cartItems.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0);
          state.productGST = (state.cartItems.map((c) => c.mrp * c.qty).reduce((a, e) => a + e, 0) * 18) / 100;
          // state.total =  state.subTotal + state.productGST;
          state.total = Math.round((state.subTotal + state.productGST + Number.EPSILON) * 100) / 100;
        } else {
          state.cartItems = [];
        }
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleUpdateIsLoginStatus: {
      reducer(state, action) {
        state.isLogin = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [decodeTokenReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload !== false) {
        state.userMainDetails = action.payload;
        state.isLogin = true;
      } else {
        state.isLogin = false;
      }
    },
  },
});

export const {
  handleUpdateIsLoginStatus,
  handleMyCartItems,
  handleUpdateCartItems,
  handleRemoveItemFromCart,
  handleGetCartItems,
} = MainSlice.actions;

export default MainSlice.reducer;
