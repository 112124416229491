import React from "react";

const Modal = ({
  handleClose,
  show,
  children,
  title,
  size = "",
  isInfo,
  isWarning,
  isShowFooter = false,
}) => {
  const showHideClassName = show
    ? "modal d-block mfp-zoom-out"
    : "modal d-none";
  return (
    <div
      className={showHideClassName}
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="modelLightBox"
      aria-hidden="true"
      style={{ background: "rgba(0, 0, 0, 0.3)" }}
    >
      <div className={`modal-dialog modal-dialog-scrollable ${size}`}>
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="modelLightBox">
              {isInfo && (
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-info-circle"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                  <circle cx="8" cy="4.5" r="1" />
                </svg>
              )}
              {isWarning && (
                <svg
                  width="1.0625em"
                  height="1em"
                  viewBox="0 0 17 16"
                  className="bi bi-exclamation-triangle-fill"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
              )}{" "}
              {title}
            </h5>

            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body">{children}</div>
          {isShowFooter && (
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={handleClose}
                data-bs-dismiss="modal"
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
