import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClientFooter from "../../Layout/ClientLayout/ClientFooter";
import { getPageReq } from "../PageContainer/PageSlice";

const ClientFooterContainer = ({ getPageReq, ...props }) => {
  React.useEffect(() => {
    getPageReq(0);
  }, [getPageReq]);
  return <ClientFooter {...props} getPageReq={getPageReq} />;
};

const selectAllPages = (state) => state.page.allPages;

const mapStateToProps = (state) => ({
  allPages: selectAllPages(state),
});

const mapDispatchToProps = { getPageReq };

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientFooterContainer));
