import axios from "axios";
import config from "../config/server-config";
import { TokenDecode } from "./general-util";

// export const UpdateAddress = async (res) => {
//   try {
//     let uid;
//     await TokenDecode()
//       .then((res) => {
//         uid = res.UserId;
//       })
//       .catch((e) => console.log(e));

//     let response = await axios.post(config.updateAddress, {
//       AddressId: res.addressid,
//       UserId: parseInt(uid),
//       FullName: res.fullname,
//       Address: res.adddress,
//       Pincode: res.pincode,
//       Mobile: res.mobile,
//       IsDefault: res.isDefault,
//       CityId: res.cityid,
//       StateId: res.stateid,
//       CountryId: res.countryid,
//     });
//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (e) {
//     return false;
//   }
// };

export const GetMyWishlist = async () => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.getMyWishlist, {
      UserId: parseInt(uid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const Addwishlist = async (productid) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.addMyWishlist, {
      UserId: parseInt(uid),
      ProductId: productid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const RemoveFromWishlist = async (wishlistid) => {
  try {
    let response = await axios.post(config.removeFromWishlist, {
      WishlistId: wishlistid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
