import axios from "axios";
import config from "../config/server-config";

export const GetAllProducts = async () => {
  try {
    let response = await axios.post(config.getAllProducts, {});
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetProductDetails = async (productId) => {
  try {
    let response = await axios.post(config.getProductDetails, { ProductId: parseInt(productId) });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetAllProductByCat = async (catid, productId) => {
  try {
    let response = await axios.post(config.getAllProductByCategory, {
      ProductId: parseInt(productId),
      CatId: parseInt(catid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetAttributesAndSetValues = async () => {
  try {
    let response = await axios.post(config.getAttributesAndSetValues, {});
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const UpdateProduct = async (req) => {
  try {
    let response = await axios.post(config.updateProduct, {
      ProductId: req.productId,
      ProductMainImgUrl: req.productCoverImgPath,
      ProductName: req.productName,
      Description: req.productDesc,
      BrandId: parseInt(req.brandId),
      TxtClassId: parseInt(req.txtClass),
      IsEnquiryOnly: req.isEnquiryOnly,
      IsProductShipped: req.isProductShipped,
      IsEnable: req.enableProduct,
      Price: parseFloat(req.productPrice),
      SpecialPrice: parseFloat(req.specialPrice),
      PriceType: parseInt(req.specialPriceType),
      SpecialPriceStart: req.specialPriceStart,
      SpecialPriceEnd: req.specialPriceEnd,
      SKU: req.txtSKU,
      IsInventoryManagement: req.lstInvestoryManagementSelection,
      IsStockAvailablity: req.lstStockAvailabilitySelection,
      SeoUrl: req.txtSeoUrl,
      MetaTitle: req.txtMetaTitle,
      MetaDescription: req.txtMetaDesc,
      ShortDescription: req.shortDescription,
      ProductNewFrom: req.productNewFrom,
      ProductNewTo: req.productNewTo,
      InventoryQty: req.txtInventoryQty,
      ProductTags: req.tagSelections,
      ProductCategories: req.attrCategorySelection,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateProductAttrAndValues = async (req) => {
  try {
    let response = await axios.post(config.updateProductAttrAndValues, {
      ProductAttrAndValId: req.ProductAttrValId,
      ProductId: req.ProductId,
      AttrId: req.AttrId,
      ValueIds: req.ValueIds,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const RemoevProductAttrAndValues = async (attrid) => {
  try {
    let response = await axios.post(config.remoevProductAttrAndValues, {
      AttrId: attrid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
export const GetProductAttrAndValuesForClient = async (pid) => {
  try {
    let response = await axios.post(config.getProductAttrAndValuesForClient, {
      ProductId: parseInt(pid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetProductAttrValues = async (req) => {
  try {
    let response = await axios.post(config.getProductAttrValues, {
      AttrId: req.AttrId,
      ProductId: parseInt(req.ProductId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const AddProductImages = async (req) => {
  try {
    let response = await axios.post(config.addProductImages, {
      ProductId: req.productId,
      ProductMainImgUrl: req.productImages,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const DeleteProductImage = async (req) => {
  try {
    let response = await axios.post(config.deleteProductImage, {
      ProductId: req.productId,
      ProductMainImgUrl: req.productImages,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const DeleteProduct = async (productId) => {
  try {
    let response = await axios.post(config.deleteProduct, {
      ProductId: productId,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const UpdateRalatedProduct = async (productId, relatedProductid, isChacked) => {
  try {
    let response = await axios.post(config.updateRalatedProduct, {
      ProductId: productId,
      RelatedProductId: relatedProductid,
      IsChecked: isChacked,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const UpdateCrossSellProduct = async (productId, relatedProductid, isChacked) => {
  try {
    let response = await axios.post(config.updateCrossSellProduct, {
      ProductId: productId,
      RelatedProductId: relatedProductid,
      IsChecked: isChacked,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const UpdateUpSellProduct = async (productId, relatedProductid, isChacked) => {
  try {
    let response = await axios.post(config.updateUpSellProduct, {
      ProductId: productId,
      RelatedProductId: relatedProductid,
      IsChecked: isChacked,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const RemoveProductPDf = async (productId) => {
  try {
    let response = await axios.post(config.removeProductPDf, {
      ProductId: productId,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetHeaderSearchByCategory = async (searchtext) => {
  try {
    let response = await axios.post(config.getHeaderSearchByCategory, {
      SearchText: searchtext,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetHeaderSearchByProduct = async (searchtext) => {
  try {
    let response = await axios.post(config.getHeaderSearchByProduct, {
      SearchText: searchtext,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetAllGovTaxCalss = async (tagid) => {
  try {
    let response = await axios.post(config.getAllGovTaxClass, {});
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const UpdateTaxClass = async (req) => {
  try {
    let response = await axios.post(config.updateTaxClass, {
      TaxClassId: parseInt(req.taxClassId),
      TaxName: req.taxName,
      TaxPercentage: parseInt(req.taxPercentage),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetMasterSearch = async (req) => {
  try {
    let response = await axios.post(config.getMasterSearch, {
      SearchText: req.searchText,
      CategoryId: req.categoriesIds,
      Tags: req.tags,
      AttrValueIds: req.attrValueIds,
      BrandIds: req.brandIds,
      PriceStart: parseInt(req.priceStart),
      PriceEnd: parseInt(req.priceEnd),
      PageNo: parseInt(req.pageno),
      PageSize: req.pagesize,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetAllFilterAttr = async (catid) => {
  try {
    let response = await axios.post(config.getAllFilterAttr, {
      CategoryId: catid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetFilterAttributeValues = async (catid) => {
  try {
    let response = await axios.post(config.getFilterAttributeValues, {
      CategoryId: catid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

// export const TagDelete = async (tagid) => {
//   try {
//     let response = await axios.post(config.deleteTag, {
//       TagId: parseInt(tagid),
//     });
//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (e) {
//     return false;
//   }
// };

// export const UpdateTag = async (tagid, tagname, tagurl) => {
//   try {
//     let response = await axios.post(config.updateTag, {
//       TagId: tagid,
//       TagName: tagname,
//       TagUrl: tagurl,
//     });
//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (e) {
//     console.log(e);
//     return false;
//   }
// };
