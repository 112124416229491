import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { GetMyWishlist, RemoveFromWishlist, Addwishlist } from "../../utils/user-wishlist-util";

export const getMyWishlistReq = createAsyncThunk("getMyWishlistReq", async () => {
  const response = await GetMyWishlist();
  return response;
});

export const removeFromWishlistReq = createAsyncThunk("removeFromWishlistReq", async (wishlistid) => {
  const response = await RemoveFromWishlist(wishlistid);
  return response;
});

const UserWishlistSlice = createSlice({
  name: "userWishlist",
  initialState: {
    isLoadingMyWishlist: true,
    myWishlist: [],
  },
  reducers: {
    handleAddWishlist: {
      reducer(state, action) {},
      prepare(productid) {
        Addwishlist(productid);
        return {
          payload: null,
        };
      },
    },
  },
  extraReducers: {
    [getMyWishlistReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.myWishlist = action.payload;
        state.isLoadingMyWishlist = false;
      }
    },
    [removeFromWishlistReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.myWishlist = action.payload;
      }
    },
  },
});

export const { handleAddWishlist } = UserWishlistSlice.actions;

export default UserWishlistSlice.reducer;
