import axios from "axios";
import config from "../config/server-config";

export const getPage = async (pageid) => {
  try {
    let response = await axios.post(config.getPage, {
      PageId: parseInt(pageid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const PageUpdate = async (pageid, pagename, pagecontent) => {
  try {
    let response = await axios.post(config.pageUpdate, {
      PageId: pageid,
      PageName: pagename,
      PageContent: pagecontent,
    });
    if (response.status === 200) {
      return {
        isSuceess: true,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const PageDelete = async (pageid) => {
  try {
    let response = await axios.post(config.pageDelete, {
      PageId: pageid,
    });
    if (response.status === 200) {
      return {
        isSuceess: true,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
