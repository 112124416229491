import { Sidebar } from "../Layout";
import React from "react";
import HeaderContainer from "../features/LayoutContainer/HeaderContainer";
// import NotificationSystem from "react-notification-system";
// import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakpoint: this.query(window.innerWidth),
    };
  }

  updatebreakpoint = () => {
    this.setState({ breakpoint: this.query(window.innerWidth) });
  };

  static isSidebarOpen() {
    return document.querySelector(".cr-sidebar").classList.contains("cr-sidebar--open");
  }

  componentDidMount() {
    this.updatebreakpoint();
    this.checkBreakpoint(this.state.breakpoint.breakpoint);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <i class="bi bi-arrow-clockwise"></i>,
    //     message: "Welcome to insightia",
    //     level: "error",
    //   });
    // }, 1500);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <i class="bi bi-hand-thumbs-up"></i>,
    //     message: "News Story: Carl Icahn strikes again",
    //     level: "success",
    //   });
    // }, 2500);
  }

  componentWillUnmount() {
    this.updatebreakpoint();
  }

  // close sidebar when
  handleContentClick = (event) => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === "xs" || this.props.breakpoint === "sm" || this.props.breakpoint === "md")
    ) {
      this.openSidebar("close");
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case "xs":
      case "sm":
      case "md":
        return this.openSidebar("close");

      case "lg":
      case "xl":
      default:
        return this.openSidebar("open");
    }
  }

  query = (width) => {
    if (width < 575) {
      return { breakpoint: "xs" };
    }

    if (576 <= width && width < 767) {
      return { breakpoint: "sm" };
    }

    if (768 <= width && width < 991) {
      return { breakpoint: "md" };
    }

    if (992 <= width && width < 1199) {
      return { breakpoint: "lg" };
    }

    if (width > 1200) {
      return { breakpoint: "xl" };
    }

    return { breakpoint: "xs" };
  };

  openSidebar(openOrClose) {
    if (openOrClose === "open") {
      return document.querySelector(".cr-sidebar").classList.add("cr-sidebar--open");
    }
    document.querySelector(".cr-sidebar").classList.remove("cr-sidebar--open");
  }

  // handleAddNotification = (icon, message, level) => {
  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }

  //     this.notificationSystem.addNotification({
  //       title: icon,
  //       message: message,
  //       level: level,
  //     });
  //   }, 1500);
  // };

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        <Sidebar />
        <div className="cr-content container-fluid" onClick={this.handleContentClick}>
          <HeaderContainer />
          {children}
        </div>
{/* 
        <NotificationSystem
          dismissible={false}
          ref={(notificationSystem) => (this.notificationSystem = notificationSystem)}
          style={NOTIFICATION_SYSTEM_STYLE}
        /> */}
      </main>
    );
  }
}

export default MainLayout;
