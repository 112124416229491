import axios from "axios";
import config from "../config/server-config";

export const getAllBrands = async (brandid) => {
  try {
    let response = await axios.post(config.getAllBrands, {
      BrandId: parseInt(brandid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const UpdateBrand = async (req) => {
  console.log("reqreqreq", req);
  try {
    let response = await axios.post(config.updateBrand, {
      BrandId: req.brandid,
      BrandName: req.brandName,
      IsActive: req.isActive,
      BrandImgUrl: req.brandImgUrl,
      BannerUrl: req.bannerUrl,
      SeoUrl: req.seoUrl,
      MetaTitle: req.metaTitle,
      MetaDesc: req.metaDesc,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteBrand = async (brandid) => {
  try {
    let response = await axios.post(config.deleteBrand, {
      BrandId: brandid,
    });
    if (response.status === 200) {
      return {
        isSuceess: true,
      };
    } else {
      return {
        isSuceess: false,
      };
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
