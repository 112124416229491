import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCategories, UpdateCategory, deleteCategory } from "../../utils/category-util";
import CategoryTabConstant from "../../constants/CategoryTabConstant";

export const getAllCategoryReq = createAsyncThunk("allCategory", async (catid) => {
  const response = await getAllCategories(catid);
  return { catid, response };
});

export const UpdateCategoryReq = createAsyncThunk("UCategoryReq", async (req) => {
  const response = await UpdateCategory(req);
  return response;
});

const CategorySlice = createSlice({
  name: "category",
  initialState: {
    allCategories: [],

    selectedCategoryId: 0,

    generalTabSelected: true,
    txtCatName: "",
    isEnableCategory: true,
    showCategorySearchbox: true,

    imagesTabSelected: false,
    catImgPath: "/images/Default/product.png",
    catBannerPath: "/images/Default/product.png",

    seoTabSelected: false,
    txtSeoUrl: "",
    txtMetaTitle: "",
    txtMetaDesc: "",
    catSelect: 0,
    catSelectName: "",
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        state.imagesTabSelected = false;
        state.seoTabSelected = false;
        switch (action.payload) {
          case CategoryTabConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          case CategoryTabConstant.IMAGES:
            state.imagesTabSelected = true;
            break;
          case CategoryTabConstant.SEO:
            state.seoTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          state.catImgPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleBannerFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          state.catBannerPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtCatName: {
      reducer(state, action) {
        state.txtCatName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleIsEnableCategory: {
      reducer(state, action) {
        if (state.isEnableCategory) {
          state.isEnableCategory = false;
        } else {
          state.isEnableCategory = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleShowCategorySearchbox: {
      reducer(state, action) {
        if (state.showCategorySearchbox) {
          state.showCategorySearchbox = false;
        } else {
          state.showCategorySearchbox = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtSeoUrl: {
      reducer(state, action) {
        state.txtSeoUrl = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaTitle: {
      reducer(state, action) {
        state.txtMetaTitle = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaDesc: {
      reducer(state, action) {
        state.txtMetaDesc = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleResetCategory: {
      reducer(state, action) {
        // state.selectedBrandId = 0;
        // state.generalTabSelected = true;
        // state.txtBrandName = "";
        // state.IsEnableBrand = true;
        // state.imagesTabSelected = false;
        // state.brandImgPath = "/images/Default/product.png";
        // state.brandBannerPath = "/images/Default/product.png";
        // state.seoTabSelected = false;
        // state.txtSeoUrl = "";
        // state.txtMetaTitle = "";
        // state.txtMetaDesc = "";
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleCategorySelection: {
      reducer(state, action) {
        state.catSelect = action.payload.id;
        state.catSelectName = action.payload.name;
        state.selectedCategoryId = 0;

        state.txtCatName = "";
        state.isEnableCategory = true;
        state.showCategorySearchbox = false;
        state.txtSeoUrl = "";
        state.catImgPath = "/images/Default/product.png";
        state.catBannerPath = "/images/Default/product.png";
      },
      prepare(id, name) {
        return {
          payload: { id, name },
        };
      },
    },
    handleClearCategorySelection: {
      reducer(state, action) {
        state.catSelect = 0;
        state.catSelectName = "";

        state.selectedCategoryId = 0;
        state.txtCatName = "";
        state.isEnableCategory = true;
        state.showCategorySearchbox = false;
        state.txtSeoUrl = "";
        state.catImgPath = "/images/Default/product.png";
        state.catBannerPath = "/images/Default/product.png";
      },
      prepare(id, name) {
        return {
          payload: { id, name },
        };
      },
    },
    handleDeleteCategory: {
      reducer(state, action) {},
      prepare(id) {
        deleteCategory(id);
        return {
          payload: {},
        };
      },
    },
  },
  extraReducers: {
    [getAllCategoryReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.catid === 0) {
          state.allCategories = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedCategoryId = action.payload.response[0].catId;
            state.catSelect = action.payload.response[0].parentId;
            state.txtCatName = action.payload.response[0].name;
            state.isEnableCategory = action.payload.response[0].isActive;
            state.showCategorySearchbox = action.payload.response[0].isSearchable;
            state.txtSeoUrl = action.payload.response[0].seoUrl;
            state.catImgPath = action.payload.response[0].logo;
            state.catBannerPath = action.payload.response[0].banner;
          }
        }
      }
    },
    [UpdateCategoryReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.selectedCategoryId = action.payload;
      }
    },
  },
});

export const {
  handleResetCategory,
  handleTabSelection,
  handleFileUpload,
  handleBannerFileUpload,
  handleIsEnableCategory,
  handleShowCategorySearchbox,
  handleTxtCatName,
  handleTxtSeoUrl,
  handleMetaTitle,
  handleMetaDesc,
  handleClearCategorySelection,
  handleCategorySelection,
  handleDeleteCategory,
  //   handleResetBrand,
} = CategorySlice.actions;

export default CategorySlice.reducer;
