import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getAllOrders, updateOrder, updateOrderDetails, getUserOrders, getOrderDetails } from "../../utils/order-util";
import { PENDING, PROCESSING, CONFIRM, ON_THE_WAY, CANCEL, COMPLETE } from "../../constants/OrderStatusConstant";

export const getAllOrdersReq = createAsyncThunk("getAllOrdersReq", async (orderid) => {
  const response = await getAllOrders(orderid);
  return response;
});

export const getOrderDetailsReq = createAsyncThunk("getOrderDetailsReq", async (orderid) => {
  const response = await getOrderDetails(orderid);
  return response;
});

export const getUserOrdersReq = createAsyncThunk("getUserOrdersReq", async () => {
  const response = await getUserOrders();
  return response;
});

export const updateOrderDetailsReq = createAsyncThunk("updateOrderDetailsReq", async (orderid) => {
  const response = await updateOrderDetails(orderid);
  return response;
});

export const updateOrderReq = createAsyncThunk(
  "updateOrderReq",
  async ({
    OrderId,
    OrderStatus,
    InvoiceNumber,
    OrderDate,
    CreatedOn,
    OrderUniqueId,
    ResultStatus,
    ResultCode,
    ResultMsg,
    TxnId,
    BankTxnId,
    TxnType,
    GatewayName,
    BankName,
    PaymentMode,
    RefundAmt,
    TxnDate,
    SubTotal,
    Gst,
    Total,
  }) => {
    let data = {
      OrderId,
      OrderStatus,
      InvoiceNumber,
      OrderDate,
      CreatedOn,
      OrderUniqueId,
      ResultStatus,
      ResultCode,
      ResultMsg,
      TxnId,
      BankTxnId,
      TxnType,
      GatewayName,
      BankName,
      PaymentMode,
      RefundAmt,
      TxnDate,
      SubTotal,
      Gst,
      Total,
    };
    const response = await updateOrder(data);
    return response;
  }
);

const PageSlice = createSlice({
  name: "orders",
  initialState: {
    allOrders: [],
    allUserOrders: [],
    orderDetails: [],
    ddlOrderStatus: [
      { label: "PENDING", value: PENDING },
      { label: "PROCESSING", value: PROCESSING },
      { label: "CONFIRM", value: CONFIRM },
      { label: "ON_THE_WAY", value: ON_THE_WAY },
      { label: "CANCEL", value: CANCEL },
      { label: "COMPLETE", value: COMPLETE },
    ],
  },
  reducers: {
    // handleTxtPageName: {
    //   reducer(state, action) {
    //     state.txtPageName = action.payload;
    //   },
    //   prepare(e) {
    //     return {
    //       payload: e.target.value,
    //     };
    //   },
    // },
  },
  extraReducers: {
    [getAllOrdersReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.allOrders = action.payload;
      }
    },
    [getOrderDetailsReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.orderDetails = action.payload;
      }
    },
    [getUserOrdersReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.allUserOrders = action.payload;
      }
    },
  },
});

export const {} = PageSlice.actions;

export default PageSlice.reducer;
