import React from "react";
import Header from "./ClientHeader";
import Footer from "../../features/ClientHomeContainer/ClientFooterContainer";

const ClientMainLayout = (props) => {
  const { children } = props;
  return (
    <>
      <Header {...props} />
      {children}
      <Footer {...props} />
    </>
  );
};

export default ClientMainLayout;
