import React from "react";
// import EazyCrop from "../GeneralForm/EasyCrop/EasyCrop";
import FileUploader from "../GeneralForm/EasyCrop/FileUploader";
import Model from "../GeneralForm/Modal";

const Dashboard = () => {
  const [showModel, setShowModel] = React.useState(false);
  const [imgPath, setImgage] = React.useState("/images/Default/product.png");

  const handleCloaseModelPopup = (show) => {
    setShowModel(show);
  };

  const hadleImagePath = (path) => {
    setImgage(path);
  };

  return (
    <div>
      ADmindddas
      <br></br>
      {/* <img
        src={imgPath}
        alt="proImage"
        style={{ height: "350px", width: "300px", border: "1px solid", padding: "7px" }}
        onClick={() => handleCloaseModelPopup(true)}
      /> */}
      <Model
        show={showModel}
        isShowFooter={false}
        handleClose={() => handleCloaseModelPopup(false)}
        title="File Manager"
        isInfo={true}
        size={"modal-lg"}
      >
        <FileUploader size={3 / 4} handleCloaseModelPopup={handleCloaseModelPopup} imagePath={hadleImagePath} />
      </Model>
    </div>
  );
};

export default Dashboard;
