export const PRODUCT_IMG_PATH = "/images/logo.png";
export const DEFAULT_IMG_PATH = "/images/Default/product.png";
export const DEFAULT_IMG_PATH_CATEGORY = "/images/new-pro1.png";
export const DEFAULT_PROFILE_PIC = "/images/user.png";

export const ADMIN_DASHBOARD = "/admin";
export const ADMIN_BRAND = "/admin/brand";
export const ADMIN_BRAND_UPDATE = "/admin/brandupdate";
export const ADMIN_CATEGORIES = "/admin/Categories";
export const ADMIN_ATTRIBUTE = "/admin/Attributes";
export const ADMIN_ATTRIBUTE_UPDATE = "/admin/AttributeUpdate";
export const ADMIN_ATTRIBUTE_SET = "/admin/AttributesSet";
export const ADMIN_ATTRIBUTE_SET_UPDATE = "/admin/AttributesSetUpdate";
export const ADMIN_PAGES = "/admin/Pages";
export const ADMIN_USERS = "/admin/Users";
export const ADMIN_TAGS = "/admin/Tags";
export const ADMIN_TAGS_UPDATE = "/admin/TagUpdate";
export const ADMIN_OPITONS = "/admin/Options";
export const ADMIN_OPITONS_UPDATE = "/admin/OptionsUpdate";
export const ADMIN_CATALOG = "/admin/catalog";
export const ADMIN_ORDER = "/admin/order";
export const ADMIN_ORDER_DETAILS = "/admin/orderDetails";
export const ADMIN_UPDATE_PRODUCT = "/admin/UpdateProduct";
export const ADMIN_TAX_CLASS = "/admin/TaxClass";
export const ADMIN_TAX_CLASS_UPDATE = "/admin/TaxClassUpdate";
export const ADMIN_ATTR_SHORTING_INDEX = "/admin/attrShortingIndex";

export const DASHBOARD = "/";
export const LOGIN = "/Login";
export const FORGOT_PASSWORD = "/ForgotPassword";
export const REGISTER = "/Register";
export const PRODUCT = "/Product";
export const PRODUCT_DETAILS = "/ProductDetails";
export const PRODUCT_SEARCH = "/ProductSearch";
export const SERVICES = "/Services";
export const PROJECTS = "/Projects";
export const ABOUT_US = "/AboutUs";
export const INQUIRY = "/Inquiry";
export const CONTACT = "/Contact";
export const PAGE = "/page";
export const MYCART = "/mycart";
export const USER_CLIENT_PROFILE = "/myprofile";
export const USER_CLIENT_WISHLIST = "/mywishlist";
export const USER_CLIENT_ORDER = "/myorder";
export const USER_PAYMENT_CALLBACK = "/paymentCallback";

export default {
  PRODUCT_IMG_PATH,
  DEFAULT_IMG_PATH_CATEGORY,

  ADMIN_DASHBOARD,
  ADMIN_BRAND,
  ADMIN_BRAND_UPDATE,
  ADMIN_CATEGORIES,
  ADMIN_ATTRIBUTE,
  ADMIN_ATTRIBUTE_UPDATE,
  ADMIN_ATTRIBUTE_SET,
  ADMIN_ATTRIBUTE_SET_UPDATE,
  ADMIN_PAGES,
  ADMIN_USERS,
  ADMIN_TAGS,
  ADMIN_TAGS_UPDATE,
  ADMIN_OPITONS,
  ADMIN_TAX_CLASS,
  ADMIN_ATTR_SHORTING_INDEX,
  ADMIN_TAX_CLASS_UPDATE,
  ADMIN_OPITONS_UPDATE,
  ADMIN_CATALOG,
  ADMIN_UPDATE_PRODUCT,
  ADMIN_ORDER,
  ADMIN_ORDER_DETAILS,

  DASHBOARD,
  LOGIN,
  FORGOT_PASSWORD,
  REGISTER,
  PRODUCT,
  PRODUCT_DETAILS,
  PRODUCT_SEARCH,
  SERVICES,
  PROJECTS,
  ABOUT_US,
  INQUIRY,
  CONTACT,
  PAGE,
  DEFAULT_IMG_PATH,
  MYCART,
  USER_CLIENT_PROFILE,
  USER_CLIENT_WISHLIST,
  USER_CLIENT_ORDER,
  USER_PAYMENT_CALLBACK
};
