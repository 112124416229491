import React from "react";
import bn from "../../../utils/bemnames";
import { Link, NavLink } from "react-router-dom";
import { GetHeaderSearchByCategory, GetHeaderSearchByProduct } from "../../../utils/product-util";
import pathConstant from "../../../constants/pathConstant";
import { history } from "../../../utils/navigation-util";
const bem = bn.create("clientHeaderSearch");

const HeaderSearch = () => {
  const people = ["Siri", "Alexa", "Google", "Facebook", "Twitter", "Linkedin", "Sinkedin"];
  const shortlist = ["Siri", "Alexa", "Google", "Facebook", "Twitter", "Linkedin", "Sinkedin"];

  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchShortResults, setSearchShortResults] = React.useState([]);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    if (searchTerm !== "") {
      GetHeaderSearchByCategory(searchTerm)
        .then((res) => {
          setSearchResults(res);
        })
        .catch((e) => console.log(e));

      GetHeaderSearchByProduct(searchTerm)
        .then((res) => {
          setSearchShortResults(res);
        })
        .catch((e) => console.log(e));

      // const results = people.filter((person) => person.toLowerCase().includes(searchTerm));
      // setSearchResults(results);
      // const shortResults = shortlist.filter((person) => person.toLowerCase().includes(searchTerm));
      // setSearchShortResults(shortResults);
    } else {
      setSearchResults([]);
      setSearchShortResults([]);
    }
  }, [searchTerm]);

  return (
    <div className="header-search">
      <div className={bem.b("catagories")}>
        <form className="form-inline ml-auto w100">
          <input
            className="form-control"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchTerm}
            onChange={handleChange}
          />
          <button className="serch-btn" type="submit">
            <i class="bi bi-search"></i>
          </button>
        </form>
      </div>
      <ul className="search-input">
        {searchShortResults.map((item) => (
          <li
            onClick={() => {
              history.push(pathConstant.PRODUCT_DETAILS + "?pid=" + item.productId);
              setSearchTerm("");
              setSearchResults([]);
              setSearchShortResults([]);
            }}
          >
            {/* <NavLink exact to={`/ProductDetails?pid=${item.productId}`}> */}
            <div className="row">
              <div className="col">
                <p className="fw-bolder text-primary">{item.name}</p>
              </div>
            </div>
            {/* </NavLink> */}
          </li>
        ))}
        {searchResults.map((item) => (
          <li
            onClick={() => {
              setSearchTerm("");
              history.push(pathConstant.PRODUCT_SEARCH + "?catid=" + item.catId);
            }}
          >
            {/* <NavLink exact to={`${pathConstant.PRODUCT_SEARCH}`}> */}
            <div className="row">
              <div className="col-2">
                <img
                  src={item.logo}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${pathConstant.DEFAULT_IMG_PATH_CATEGORY}`;
                  }}
                />
              </div>
              <div className="col-10 ">
                <p className="fw-bolder text-primary">{item.name}</p>
                {/* <small className="fw-light">short descshort descshort descshort descshort desc</small> */}
              </div>
            </div>
            {/* </NavLink> */}
          </li>
        ))}

        {searchTerm.length > 0 && searchResults.length === 0 && searchShortResults.length === 0 && (
          <li>
            <div className="row">
              <div className="col ">
                <small className="fw-light">
                  <i class="bi bi-emoji-frown text-primary fs-4"></i> Not found
                </small>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderSearch;
