import React from "react";
import bn from "../../utils/bemnames";
import { Redirect, withRouter } from "react-router";
import { Link } from "react-router-dom";
import Page from "../../components/Page";
import { history } from "../../utils/navigation-util";
import { USER_CLIENT_ORDER, USER_CLIENT_WISHLIST, USER_CLIENT_PROFILE } from "../../constants/pathConstant";
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaGoogle } from "react-icons/fa";

const bem = bn.create("clientFooter");

const ClientFooter = (props) => {
  function handleClick(pageid) {
    history.push(`/page?id=${pageid}`);
  }
  return (
    <div className={bem.b("")}>
      <div class="subscribe-section">
        <div class="sign">
          <div class="container">
            <div class="row text-center">
              <div class="col-md-12">
                <div class="sign-text">
                  <img src="images/head-img1.png" class="head-img1" /> Sign up and get a Voucher worth $250.00
                </div>
              </div>
              {/* <div class="col-md-1">
                <img src="images/sub-box.png" class="img-fluid" alt="Image" />
              </div>
              <div class="col-md-5">
                <div class="sign-box">
                  <input type="text" />
                  <a href="javascript:void(0)" class="sub-btn text-decoration-none">
                    Subscribe
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-12 bor-rb text-center">
                {/* <img src="images/logo.png" className="img-logo img-fluid" alt="logo" /> */}
                <h1>
                  <strong>
                    <i class="bi bi-geo-alt text-primary"></i>
                  </strong>
                </h1>

                <p className="foot-para text-center">
                  <strong> Vasani ELectricals </strong>
                  <br></br>
                  Opp. Gate No.2,
                  <br></br>B/h Kismat Hotel,Metoda G.I.D.C, <br></br>
                  Rajkot-360021,Gujarat
                  <br></br>
                  <strong>Mo: +91 85 11 60 50 50</strong>
                </p>
              </div>
              <div className="col-md-2 col-sm-6 col-12 bor-rb text-center">
                <h3 className="heading-h3">My Accounts</h3>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_PROFILE}>
                    My Profile
                  </Link>
                </div>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_ORDER}>
                    My Order
                  </Link>
                </div>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_WISHLIST}>
                    My Whishlist
                  </Link>
                </div>
              </div>
              <div className="col-md-2 col-sm-6 col-12 bor-rb text-center">
                <h3 className="heading-h3">Our Services</h3>
                {props.allPages.slice(0, 5).map((e) => (
                  <div className="foot-link">
                    {/* <Link to={`/page?id=${e.pageId}`} activeClassName="current">
                      {e.pageName}
                    </Link> */}
                    <a href={`/page?id=${e.pageId}`}>{e.pageName}</a>
                  </div>
                ))}
              </div>
              <div className="col-md-2 col-sm-6 col-12 bor-b text-center">
                <h3 className="heading-h3">Products</h3>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_PROFILE}>
                    My Profile
                  </Link>
                </div>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_ORDER}>
                    You Order
                  </Link>
                </div>
                <div className="foot-link">
                  <Link className="" to={USER_CLIENT_WISHLIST}>
                    Your Whishlist
                  </Link>
                </div>
              </div>
            </div>
            <div className="hr-line1 pl-0 pr-0" />
          </div>
        </div>
        <div className="location">
          <div className="container">
            <div className="loc-padd">
              <div className="loc-detail">
                <div className="comp-info">
                  Address: Vasani ELectricals - Metoda G.I.D.C,
                  <br></br>
                  Rajkot-360021,Gujarat
                </div>
                <div className="line1" />
                <div className="comp-info">Phone: +91 85 11 60 50 50</div>
                <div className="line1" />
                <div className="comp-info">Email: info@vasanielectricals.com</div>
                <div className="line1" />
                <div className="comp-info">Hours: 7 days of week from 9am to 7pm</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hr-line pl-0 pr-0" />
        </div>
        <div className="social-link">
          <div className="container">
            <div className="social">
              <h3>
                <a href="javascript:void(0)" className="text-primary  ms-1 me-1">
                  {/* <img src="images/facebook.png" className="social-img" alt="img" /> */}
                  <FaFacebook />
                </a>

                <a href="javascript:void(0)" className="text-primary  ms-1 me-1">
                  {/* <img src="images/twiter.png" className="social-img" alt="img" /> */}
                  <FaTwitter />
                </a>
                <a href="javascript:void(0)" className="text-primary  ms-1 me-1">
                  {/* <img src="images/twiter.png" className="social-img" alt="img" /> */}
                  <FaGoogle />
                </a>
                <a href="javascript:void(0)" className="text-primary  ms-1 me-1">
                  {/* <img src="images/insta.png" className="social-img" alt="img" /> */}
                  <FaInstagram />
                </a>
                <a href="javascript:void(0)" className="text-primary  ms-1 me-1">
                  {/* <img src="images/pintest.png" className="social-img" alt="img" /> */}
                  <FaPinterest />
                </a>
              </h3>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withRouter(ClientFooter);
