import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import ClientMainLayout from "./features/MainContainer/MainLayoutContainer";
import MainLayout from "./Layout/MainLayout";
import PageSpinner from "./components/PageSpinner";
import Dashboard from "./components/Admin/Dashboard";
import { history } from "./utils/navigation-util";
import pathConstant from "./constants/pathConstant";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/reduction.scss";

import "./custom.css";
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

const PageNotFound = React.lazy(() => import("./pages/NotFound"));

// Admin
const Brand = React.lazy(() => import("./features/BrandContainer/BrandContainer"));
const AdminCategory = React.lazy(() => import("./features/CategoryContainer/CategoryContainer"));
const Users = React.lazy(() => import("./features/UserContainer/UserContainer"));
const AdminPages = React.lazy(() => import("./features/PageContainer/PageContainer"));
const BrandUpdate = React.lazy(() => import("./features/BrandContainer/BrandUpdateContainer"));
const Attributes = React.lazy(() => import("./features/AttributesContainer/AttributesContainer"));
const AttributesUpdate = React.lazy(() => import("./features/AttributesContainer/UpdateAttributeContainer"));
const AttributesSet = React.lazy(() => import("./features/AttributeSetContainer/AttributesSetContainer"));
const AttributesSetUpdate = React.lazy(() => import("./features/AttributeSetContainer/AttributeSetUpdateContainer"));
const AdminTags = React.lazy(() => import("./features/TagContainer/TagContainer"));
const AdminTagUpdate = React.lazy(() => import("./features/TagContainer/TagUpdateContainer"));
const AdminOptions = React.lazy(() => import("./features/OptionsContainer/OptionContainer"));
const AdminOptionsUpdate = React.lazy(() => import("./features/OptionsContainer/UpdateOptionsContainer"));
const AdminCatalog = React.lazy(() => import("./features/CatalogContainer/CatalogContainer"));
const AdminUpdateProduct = React.lazy(() => import("./features/CatalogContainer/CreateProductContainer"));
const AdminTaxClass = React.lazy(() => import("./features/CatalogContainer/TaxClassContainer"));
const AdminTaxClassUpdate = React.lazy(() => import("./features/CatalogContainer/TaxClassUpdateContainer"));
const AdminOrder = React.lazy(() => import("./features/OrderContainer/OrderContainer"));
const AdminOrderDetails = React.lazy(() => import("./features/OrderContainer/OrderDetailsContainer"));
const AdminAttrShortingIndex = React.lazy(() => import("./features/AttributesContainer/ShortingIndex"));

// Client
const Login = React.lazy(() => import("./features/LoginContainer/LoginContainer"));
const ForgotPassword = React.lazy(() => import("./components/Client/AuthForm/ForgotPassword"));
const Register = React.lazy(() => import("./features/LoginContainer/RegisterContainer"));
const Home = React.lazy(() => import("./features/ClientHomeContainer/ClientHomeContainer"));
const Product = React.lazy(() => import("./features/ProductContainer/ProductContainer"));
const ProductDetails = React.lazy(() => import("./features/ProductContainer/ClientProductDetails"));
const ProductSearch = React.lazy(() => import("./features/ProductContainer/ClientProductSearch"));
const Services = React.lazy(() => import("./features/PageContainer/ServicePageContainer"));
const Projects = React.lazy(() => import("./features/PageContainer/ProjectPageContainer"));
const AboutUs = React.lazy(() => import("./features/PageContainer/AboutUsPageContainer"));
const Inquiry = React.lazy(() => import("./components/Client/Inquiry"));
const Contact = React.lazy(() => import("./features/PageContainer/ContactPageContainer"));
const Page = React.lazy(() => import("./features/PageContainer/ClientPageContainer"));
const MyCartDetails = React.lazy(() => import("./features/MyCartContainer/MyCartContainer"));
const UserProfileDetails = React.lazy(() => import("./features/UserContainer/UserProfileContainer"));
const UserWishlistDetails = React.lazy(() => import("./features/UserContainer/UserWishlistContainer"));
const UserOrderDetails = React.lazy(() => import("./features/UserContainer/UserOrderContainer"));
const userPaymentCallBack = React.lazy(() => import("./components/PaymentGateway/PaymentCallback"));

function App({ ...rest }) {
  return (
    <Router history={history}>
      <React.Suspense
        fallback={
          <div className="h-100">
            <PageSpinner />
          </div>
        }
      >
        <Switch>
          <Route path="/admin/:path?" exact>
            <MainLayout>
              <Switch>
                <Route path={pathConstant.ADMIN_DASHBOARD} exact component={Dashboard} />
                <Route path={pathConstant.ADMIN_BRAND} exact component={Brand} />
                <Route path={pathConstant.ADMIN_CATEGORIES} exact component={AdminCategory} />
                <Route path={pathConstant.ADMIN_BRAND_UPDATE} exact component={BrandUpdate} />
                <Route path={pathConstant.ADMIN_USERS} exact component={Users} />
                <Route path={pathConstant.ADMIN_PAGES} exact component={AdminPages} />
                <Route path={pathConstant.ADMIN_ATTRIBUTE} exact component={Attributes} />
                <Route path={pathConstant.ADMIN_ATTRIBUTE_UPDATE} exact component={AttributesUpdate} />
                <Route path={pathConstant.ADMIN_ATTRIBUTE_SET} exact component={AttributesSet} />
                <Route path={pathConstant.ADMIN_ATTRIBUTE_SET_UPDATE} exact component={AttributesSetUpdate} />
                <Route path={pathConstant.ADMIN_TAGS} exact component={AdminTags} />
                <Route path={pathConstant.ADMIN_TAGS_UPDATE} exact component={AdminTagUpdate} />
                <Route path={pathConstant.ADMIN_OPITONS} exact component={AdminOptions} />
                <Route path={pathConstant.ADMIN_OPITONS_UPDATE} exact component={AdminOptionsUpdate} />
                <Route path={pathConstant.ADMIN_CATALOG} exact component={AdminCatalog} />
                <Route path={pathConstant.ADMIN_UPDATE_PRODUCT} exact component={AdminUpdateProduct} />
                <Route path={pathConstant.ADMIN_TAX_CLASS} exact component={AdminTaxClass} />
                <Route path={pathConstant.ADMIN_TAX_CLASS_UPDATE} exact component={AdminTaxClassUpdate} />
                <Route path={pathConstant.ADMIN_ORDER} exact component={AdminOrder} />
                <Route path={pathConstant.ADMIN_ORDER_DETAILS} exact component={AdminOrderDetails} />
                <Route path={pathConstant.ADMIN_ATTR_SHORTING_INDEX} exact component={AdminAttrShortingIndex} />
                <Route component={PageNotFound} />
              </Switch>
            </MainLayout>
          </Route>

          <Route>
            <ClientMainLayout>
              <Switch>
                <Route path={pathConstant.DASHBOARD} exact component={Home} />
                <Route path={pathConstant.LOGIN} exact component={Login} />
                <Route path={pathConstant.FORGOT_PASSWORD} exact component={ForgotPassword} />
                <Route path={pathConstant.REGISTER} exact component={Register} />
                <Route path={pathConstant.PRODUCT} exact component={Product} />
                <Route path={pathConstant.PRODUCT_DETAILS} exact component={ProductDetails} />
                <Route path={pathConstant.PRODUCT_SEARCH} exact component={ProductSearch} />
                <Route path={pathConstant.SERVICES} exact component={Services} />
                <Route path={pathConstant.MYCART} exact component={MyCartDetails} />
                <Route path={pathConstant.PROJECTS} exact component={Projects} />
                <Route path={pathConstant.ABOUT_US} exact component={AboutUs} />
                <Route path={pathConstant.INQUIRY} exact component={Inquiry} />
                <Route path={pathConstant.CONTACT} exact component={Contact} />
                <Route path={pathConstant.USER_CLIENT_PROFILE} exact component={UserProfileDetails} />
                <Route path={pathConstant.USER_CLIENT_WISHLIST} exact component={UserWishlistDetails} />
                <Route path={pathConstant.USER_CLIENT_ORDER} exact component={UserOrderDetails} />
                <Route path={pathConstant.USER_PAYMENT_CALLBACK} exact component={userPaymentCallBack} />
                <Route path={pathConstant.PAGE} exact component={Page} />
              </Switch>
            </ClientMainLayout>
          </Route>

          <Route component={PageNotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
