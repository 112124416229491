export const baseUrl = "";
export const tokenSecrectKey = "this is my custom Secret key for authnetication";

export const configToken = {
  headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
};

//users
export const register = `${baseUrl}/api/Users/register`;
export const authenticate = `${baseUrl}/api/Users/authenticate`;
export const isUserAvaible = `${baseUrl}/api/Users/isUserAvaible`;
export const getAllUsers = `${baseUrl}/api/Users/GetAllUsers`;
export const getAllCountries = `${baseUrl}/api/Users/GetAllCountries`;
export const getAllStates = `${baseUrl}/api/Users/GetAllStates`;
export const getAllCities = `${baseUrl}/api/Users/GetAllCities`;
export const getAllAddress = `${baseUrl}/api/Users/GetAllUserAddress`;
export const updateAddress = `${baseUrl}/api/Users/UpdateAddress`;
export const deleteAddress = `${baseUrl}/api/Users/DeleteAddress`;
export const updateUserDetails = `${baseUrl}/api/Users/UpdateUserDetails`;
export const getMyWishlist = `${baseUrl}/api/Users/GetMyWishlist`;
export const removeFromWishlist = `${baseUrl}/api/Users/RemoveFromWishlist`;
export const addMyWishlist = `${baseUrl}/api/Users/AddMyWishlist`;

//brands
export const getAllBrands = `${baseUrl}/api/Brands/GetAll`;
export const updateBrand = `${baseUrl}/api/Brands/Update`;
export const deleteBrand = `${baseUrl}/api/Brands/Delete`;

//category
export const getAllCategory = `${baseUrl}/api/Category/GetAll`;
export const updateCategory = `${baseUrl}/api/Category/Update`;
export const deleteCategory = `${baseUrl}/api/Category/Delete`;

//page
export const getPage = `${baseUrl}/api/Page/Get`;
export const pageUpdate = `${baseUrl}/api/Page/Update`;
export const pageDelete = `${baseUrl}/api/Page/Delete`;

//attributes
export const getAllAttributes = `${baseUrl}/api/Attribute/GetAll`;
export const getAttribute = `${baseUrl}/api/Attribute/GetAttribute`;
export const deleteAttribute = `${baseUrl}/api/Attribute/Delete`;
export const updateAttributes = `${baseUrl}/api/Attribute/Update`;
export const getAllAttrValue = `${baseUrl}/api/Attribute/GetAllAttrValue`;
export const updateAttributeValue = `${baseUrl}/api/Attribute/UpdateAttributeValue`;
export const deleteAttributeValue = `${baseUrl}/api/Attribute/DeleteAttributeValue`;
export const getAttributeForIndex = `${baseUrl}/api/Attribute/GetAttributeForIndex`;
export const updateAttributeShortingIndex = `${baseUrl}/api/Attribute/UpdateAttributeShortingIndex`;

//attribute set
export const getAllAttributeSet = `${baseUrl}/api/AttributeSet/GetAll`;
export const updateAttrSet = `${baseUrl}/api/AttributeSet/Update`;
export const deleteAttrSet = `${baseUrl}/api/AttributeSet/Delete`;

//Tags
export const getAllTags = `${baseUrl}/api/Tags/GetAll`;
export const getTags = `${baseUrl}/api/Tags/GetTag`;
export const updateTag = `${baseUrl}/api/Tags/Update`;
export const deleteTag = `${baseUrl}/api/Tags/Delete`;

//Options
export const getAllOptions = `${baseUrl}/api/Option/GetAll`;
export const deleteOptions = `${baseUrl}/api/Option/Delete`;
export const getAllOptionValue = `${baseUrl}/api/Option/GetAllOptionValue`;
export const updateOptions = `${baseUrl}/api/Option/Update`;
export const addOptionValueReq = `${baseUrl}/api/Option/AddOptionValue`;
export const deleteOptionValue = `${baseUrl}/api/Option/OptionValueDelete`;

// Options
export const checkoutGateway = `${baseUrl}/api/PaymentGateway/Transaction`;

//products
export const getAllProducts = `${baseUrl}/api/Product/GetAll`;
export const getProductDetails = `${baseUrl}/api/Product/GetProductDetails`;
export const getAllImages = `${baseUrl}/api/Product/GetAllImages`;
export const getAllPdfs = `${baseUrl}/api/Product/GetAllPDF`;
export const getAttributesAndSetValues = `${baseUrl}/api/Product/GetAttributesAndSetValues`;
export const updateProduct = `${baseUrl}/api/Product/Update`;
export const addProductImages = `${baseUrl}/api/Product/AddProductImages`;
export const deleteProductImage = `${baseUrl}/api/Product/DeleteProductImage`;
export const deleteProduct = `${baseUrl}/api/Product/DeleteProduct`;
export const updateRalatedProduct = `${baseUrl}/api/Product/UpdateRalatedProduct`;
export const updateCrossSellProduct = `${baseUrl}/api/Product/UpdateCrossSellProduct`;
export const updateUpSellProduct = `${baseUrl}/api/Product/UpdateUpSellProduct`;
export const updateProductAttrAndValues = `${baseUrl}/api/Product/UpdateProductAttrAndValues`;
export const getProductAttrValues = `${baseUrl}/api/Product/GetProductAttrAndValues`;
export const remoevProductAttrAndValues = `${baseUrl}/api/Product/RemoevProductAttrAndValues`;
export const getProductAttrAndValuesForClient = `${baseUrl}/api/Product/GetProductAttrAndValuesForClient`;
export const uploadProducePDFFile = `${baseUrl}/api/Product/UploadFile`;
export const removeProductPDf = `${baseUrl}/api/Product/DeleteProductPDF`;
export const getAllProductByCategory = `${baseUrl}/api/Product/GetAllProductByCategory`;
export const getHeaderSearchByCategory = `${baseUrl}/api/Product/GetHeaderSearchByCategory`;
export const getHeaderSearchByProduct = `${baseUrl}/api/Product/GetHeaderSearchByProduct`;
export const getAllGovTaxClass = `${baseUrl}/api/Product/GetAllGovTaxClass`;
export const updateTaxClass = `${baseUrl}/api/Product/UpdateGovTaxClass`;
export const getMasterSearch = `${baseUrl}/api/Product/GetSearch`;
export const getAllFilterAttr = `${baseUrl}/api/Product/GetFilterAttributes`;
export const getFilterAttributeValues = `${baseUrl}/api/Product/GetFilterAttributeValues`;

// order
export const GetAllOrders = `${baseUrl}/api/Order/GetAllOrders`;
export const GetOrderDetails = `${baseUrl}/api/Order/GetOrderDetails`;
export const UpdateOrder = `${baseUrl}/api/Order/UpdateOrder`;
export const UpdateOrderDetails = `${baseUrl}/api/Order/UpdateOrderDetails`;
export const GetUserOrders = `${baseUrl}/api/Order/GetUserOrders`;

export default {
  GetAllOrders,
  GetOrderDetails,
  UpdateOrder,
  UpdateOrderDetails,
  GetUserOrders,
  tokenSecrectKey,
  configToken,
  isUserAvaible,
  getAllBrands,
  updateBrand,
  authenticate,
  register,
  getAllUsers,
  getPage,
  pageUpdate,
  pageDelete,
  getAllCategory,
  getAllAttributes,
  getAllAttributeSet,
  updateAttrSet,
  getAllAttrValue,
  updateAttributeValue,
  deleteAttributeValue,
  getAttributeForIndex,
  updateAttributeShortingIndex,
  updateAttributes,
  getAttribute,
  updateCategory,
  deleteCategory,
  deleteAttrSet,
  deleteAttribute,
  getAllTags,
  getTags,
  updateTag,
  deleteTag,
  getAllOptions,
  deleteOptions,
  getAllOptionValue,
  updateOptions,
  addOptionValueReq,
  deleteOptionValue,
  deleteBrand,
  getAllProducts,
  getAllImages,
  getAllPdfs,
  getAttributesAndSetValues,
  updateProduct,
  getProductDetails,
  addProductImages,
  deleteProductImage,
  deleteProduct,
  updateRalatedProduct,
  updateCrossSellProduct,
  updateUpSellProduct,
  updateProductAttrAndValues,
  getProductAttrValues,
  remoevProductAttrAndValues,
  getProductAttrAndValuesForClient,
  uploadProducePDFFile,
  removeProductPDf,
  getAllProductByCategory,
  getHeaderSearchByCategory,
  getHeaderSearchByProduct,
  getAllCountries,
  getAllStates,
  getAllCities,
  getAllAddress,
  updateAddress,
  deleteAddress,
  updateUserDetails,
  getMyWishlist,
  removeFromWishlist,
  addMyWishlist,
  getAllGovTaxClass,
  updateTaxClass,
  getMasterSearch,
  getAllFilterAttr,
  getFilterAttributeValues,
  checkoutGateway,
};
