import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  authenticate,
  userIsExist,
  userRegister,
} from "../../utils/login-util";

export const loginFormReq = createAsyncThunk("login", async (arg) => {
  const response = await authenticate(arg.mobile, arg.password);
  return response;
});

export const registerFormReq = createAsyncThunk(
  "registerformreq",
  async (arg) => {
    const response = await userRegister(
      arg.firstname,
      arg.middlename,
      arg.lastname,
      arg.username,
      arg.password
    );
    return response;
  }
);

export const isExistUserFormReq = createAsyncThunk(
  "IsExistUserFormReq",
  async (mobile) => {
    const response = await userIsExist(mobile);
    return response;
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isModalPopupVisible: false,
    txtUsername: "",
    txtPassword: "",

    isUserAvaibleForRegister: false,
    userRegisterSuccess: false,

    txtFirstname: "",
    txtLastname: "",
    txtMiddlename: "",
    txtRegUsername: "",
    txtRegPassword: "",
    txtRegConfirmPassword: "",
  },
  reducers: {
    handleCloaseModelPopup: {
      reducer(state, action) {
        state.isModalPopupVisible = false;
      },
      prepare() {
        return { payload: {} };
      },
    },
    handleTxtUsername: {
      reducer(state, action) {
        state.txtUsername = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/\D/, ""),
        };
      },
    },
    handleTxtPassword: {
      reducer(state, action) {
        state.txtPassword = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtFirstname: {
      reducer(state, action) {
        state.txtFirstname = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtMiddleName: {
      reducer(state, action) {
        state.txtMiddlename = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtLastname: {
      reducer(state, action) {
        state.txtLastname = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleRegTxtUsername: {
      reducer(state, action) {
        state.txtRegUsername = action.payload;
        state.isUserAvaible = false;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/\D/, ""),
        };
      },
    },
    handleRegTxtPassword: {
      reducer(state, action) {
        state.txtRegPassword = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleRegTxtConfirmPassword: {
      reducer(state, action) {
        state.txtRegConfirmPassword = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
  },
  extraReducers: {
    [loginFormReq.fulfilled]: (state, action) => {
      state.userDetails = action.payload;
      if (action.payload !== null && action.payload.isValid === false) {
        state.isModalPopupVisible = true;
      }
    },
    [registerFormReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload.isSuceess) {
        state.userRegisterSuccess = action.payload.isSuceess;

        state.txtFirstname = "";
        state.txtLastname = "";
        state.txtMiddlename = "";
        state.txtRegUsername = "";
        state.txtRegPassword = "";
        state.txtRegConfirmPassword = "";
      }
    },
    [isExistUserFormReq.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        state.isUserAvaibleForRegister = action.payload.isSuceess;
      }
    },
  },
});

export const {
  handleCloaseModelPopup,
  handleTxtUsername,
  handleTxtPassword,
  handleTxtFirstname,
  handleTxtMiddleName,
  handleTxtLastname,
  handleRegTxtUsername,
  handleRegTxtPassword,
  handleRegTxtConfirmPassword,
} = loginSlice.actions;

export default loginSlice.reducer;
