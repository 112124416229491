import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore , getDefaultMiddleware } from '@reduxjs/toolkit'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './reducers'

const store = configureStore({
  reducer: rootReducer,
})  

ReactDOM.render(
    <Provider store={store}>
            <App />
    </Provider>,
    document.getElementById('root'));
    

registerServiceWorker();
