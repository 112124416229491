import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAttributesSet, UpdateAttrSetReq, deleteAttributeSet } from "../../utils/attribute-util";
import AttributeConstant from "../../constants/AttributeConstant";

export const getAllAttributeSetReq = createAsyncThunk("AllAttributesSet", async (attrSetId) => {
  const response = await getAllAttributesSet(attrSetId);
  return { attrSetId, response };
});

export const updateAttrSetReq = createAsyncThunk("updateAttrSetReq", async (req) => {
  const response = await UpdateAttrSetReq(req);
  return response;
});

const AttributesSetSlice = createSlice({
  name: "attributeSet",
  initialState: {
    allAttributeSet: [],
    generalTabSelected: true,
    attributeSetId: 0,
    txtAttributeSetName: "",
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        switch (action.payload) {
          case AttributeConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtAttributeSetName: {
      reducer(state, action) {
        state.txtAttributeSetName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    // handleIsEnableBrand: {
    //   reducer(state, action) {
    //     if (state.IsEnableBrand) {
    //       state.IsEnableBrand = false;
    //     } else {
    //       state.IsEnableBrand = true;
    //     }
    //   },
    //   prepare(e) {
    //     return {
    //       payload: e,
    //     };
    //   },
    // },
    handleResetAttributeSet: {
      reducer(state, action) {
        state.attributeSetId = 0;
        state.generalTabSelected = true;
        state.txtAttributeSetName = "";
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleDeleteAttributeSet: {
      reducer(state, action) {},
      prepare(e) {
        deleteAttributeSet(e);
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [getAllAttributeSetReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.attrSetId === 0) {
          state.allAttributeSet = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.attributeSetId = action.payload.response[0].attrSetsId;
            state.txtAttributeSetName = action.payload.response[0].attrSetsName;
          }
        }
      }
    },
    [updateAttrSetReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.attributeSetId = action.payload;
      }
    },
  },
});

export const {
  handleTabSelection,
  handleTxtAttributeSetName,
  handleResetAttributeSet,
  handleDeleteAttributeSet,
} = AttributesSetSlice.actions;

export default AttributesSetSlice.reducer;
