import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getAllTags, getTags, UpdateTag, TagDelete } from "../../utils/Tags-util";
import BrandTabConstant from "../../constants/BrandTabConstant";

export const getAllTagsreq = createAsyncThunk("getAllTagsreq", async (tagid) => {
  const response = await getAllTags(tagid);
  return { tagid, response };
});

export const getTagsReq = createAsyncThunk("getTagsReq", async (tagid) => {
  const response = await getTags(tagid);
  return { tagid, response };
});

// export const getPageDetailsReq = createAsyncThunk("getPagedetails", async (pageid) => {
//   const response = await getPage(pageid);
//   return response;
// });

export const updateTagReq = createAsyncThunk("updateTagReq", async (arg) => {
  const response = await UpdateTag(arg.tagid, arg.tagName, arg.tagUrl);
  return response;
});

// export const deletePageReq = createAsyncThunk(
//   "deletePageReq",
//   async (pageid) => {
//     const response = await PageDelete(pageid);
//     return response;
//   }
// );

const PageSlice = createSlice({
  name: "tags",
  initialState: {
    // pageUpdate: false,
    allTags: [],
    generalTabSelected: true,
    selectedTagId: 0,
    txtTagName: "",
    txtCEOUrl: "",
    // txtPageContent: "",
    // pageDetails: "",
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        switch (action.payload) {
          case BrandTabConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtTagName: {
      reducer(state, action) {
        state.txtTagName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleCEOUrl: {
      reducer(state, action) {
        state.txtCEOUrl = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    // handleEditPage: {
    //   reducer(state, action) {
    //     const allPage = current(state).allPages;
    //     const selectedPage = allPage.filter((e) => e.pageId === action.payload);
    //     if (selectedPage !== undefined) {
    //       state.txtPageid = selectedPage[0].pageId;
    //       state.txtPageName = selectedPage[0].pageName;
    //       state.txtPageContent = selectedPage[0].pageContent;
    //     }
    //   },
    //   prepare(e) {
    //     return {
    //       payload: e,
    //     };
    //   },
    // },
    handleTagReset: {
      reducer(state, action) {
        state.selectedTagId = 0;
        state.txtTagName = "";
        state.txtCEOUrl = "";
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleDeleteTag: {
      reducer(state, action) {
        state.txtPageid = 0;
        state.txtPageName = "";
        state.txtPageContent = "";
      },
      prepare(e) {
        TagDelete(e);
        return {
          payload: e,
        };
      },
    },
    // handleGetPageDetails: {
    //   reducer(state, action) {
    //     const allPage = current(state).allPages;
    //     const selectedPage = allPage.filter(
    //       (e) => e.pageId === action.payload.pageid
    //     );
    //     if (selectedPage !== undefined) {
    //       state.pageDetails = selectedPage[0].pageContent;
    //     }
    //   },
    //   prepare(e) {
    //     return {
    //       payload: { pageid: e },
    //     };
    //   },
    // },
  },
  extraReducers: {
    [getAllTagsreq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.tagid === 0) {
          state.allTags = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedTagId = action.payload.response[0].tagId;
            state.txtTagName = action.payload.response[0].tagName;
            state.txtCEOUrl = action.payload.response[0].tagUrl;
          }
        }
      }
    },
    [getTagsReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.tagid === 0) {
          state.allTags = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedTagId = action.payload.response[0].tagId;
            state.txtTagName = action.payload.response[0].tagName;
            state.txtCEOUrl = action.payload.response[0].tagUrl;
          }
        }
      }
    },
    // [getPageDetailsReq.fulfilled]: (state, action) => {
    //   if(action.payload!==null && action.payload.length>0)
    //   {
    //     state.pageDetails = action.payload[0].pageContent;
    //   }

    // },
    [updateTagReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload.isSuceess) {
        state.selectedTagId = 0;
        state.txtTagName = "";
        state.txtCEOUrl = "";
      }
    },
  },
});

export const { handleTabSelection, handleTxtTagName, handleCEOUrl, handleTagReset,handleDeleteTag } = PageSlice.actions;

export default PageSlice.reducer;
