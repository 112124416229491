import PropTypes from "prop-types";
import React from "react";

const PageSpinner = ({ color = "primary" }) => {
  return (
    <div className="cr-page-spinner">
      <div className={`spinner-grow text-${color}`} role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

PageSpinner.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
};

export default PageSpinner;
