import axios from "axios";
import { parse } from "path";
import config from "../config/server-config";
import { TokenDecode } from "./general-util";

export const getAllOrders = async (orderid) => {
  try {
    let response = await axios.post(config.GetAllOrders, {
      OrderId: parseInt(orderid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getOrderDetails = async (orderid) => {
  try {
    let response = await axios.post(config.GetOrderDetails, {
      OrderId: parseInt(orderid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getUserOrders = async () => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.GetUserOrders, {
      UserId: parseInt(uid),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updateOrder = async (order) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.UserId;
      })
      .catch((e) => console.log(e));

    let response = await axios.post(config.UpdateOrder, {
      OrderId: parseInt(order.OrderId),
      UserId: parseInt(uid),
      OrderStatus: order.OrderStatus,
      InvoiceNumber: parseInt(order.InvoiceNumber),
      OrderDate: order.OrderDate,
      CreatedOn: order.CreatedOn,
      OrderUniqueId: order.OrderUniqueId,
      ResultStatus: order.ResultStatus,
      ResultCode: order.ResultCode,
      ResultMsg: order.ResultMsg,
      TxnId: order.TxnId,
      BankTxnId: order.BankTxnId,
      TxnType: order.TxnType,
      GatewayName: order.GatewayName,
      BankName: order.BankName,
      PaymentMode: order.PaymentMode,
      RefundAmt: order.RefundAmt,
      TxnDate: order.TxnDate,
      SubTotal: parseFloat(order.SubTotal),
      Gst: parseFloat(order.Gst),
      Total: parseFloat(order.Total),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updateOrderDetails = async (order) => {
  try {
    let response = await axios.post(config.UpdateOrderDetails, {
      OrderId: parseInt(order.OrderId),
      ProductId: parseInt(order.ProductId),
      Oty: parseInt(order.Oty),
      Amount: parseFloat(order.Amount),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
