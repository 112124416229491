import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getAllBrands } from "../../utils/brand-util";

// export const getAllBrandreq = createAsyncThunk("allBrands", async () => {
//   const response = await getAllBrands();
//   return response;
// });

const ClientHomeSlice = createSlice({
  name: "clienthome",
  initialState: {
    ProjectGallary: [
      { imgPath: "images/gallery1.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery2.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery3.png", gallaryTypeId: 2 },
      { imgPath: "images/gallery4.png", gallaryTypeId: 2 },
      { imgPath: "images/gallery5.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery6.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery7.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery8.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery9.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery10.png", gallaryTypeId: 4 },
      { imgPath: "images/gallery11.png", gallaryTypeId: 4 },
      { imgPath: "images/gallery1.png", gallaryTypeId: 4 },
    ],
    projectGallarySelection: [
      { imgPath: "images/gallery1.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery2.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery3.png", gallaryTypeId: 2 },
      { imgPath: "images/gallery4.png", gallaryTypeId: 2 },
      { imgPath: "images/gallery5.png", gallaryTypeId: 1 },
      { imgPath: "images/gallery6.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery7.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery8.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery9.png", gallaryTypeId: 3 },
      { imgPath: "images/gallery10.png", gallaryTypeId: 4 },
      { imgPath: "images/gallery11.png", gallaryTypeId: 4 },
      { imgPath: "images/gallery1.png", gallaryTypeId: 4 },
    ],
    powerToolOfferBlock: [
      {
        imgPath: "images/s-banner2.png",
        title1: "Find Great Tools",
        title2: "Free Shipping on Everything*",
      },
      {
        imgPath: "images/s-banner2.png",
        title1: "Power Tool Deal",
        title2: "Up to 35% off",
      },
    ],
    allBrands: [
      { brandId: "1", imgUrl: "images/brand1.png" },
      { brandId: "2", imgUrl: "images/brand2.png" },
      { brandId: "3", imgUrl: "images/brand3.png" },
      { brandId: "4", imgUrl: "images/brand4.png" },
      { brandId: "5", imgUrl: "images/brand4.png" },
      { brandId: "1", imgUrl: "images/brand1.png" },
      { brandId: "2", imgUrl: "images/brand2.png" },
      { brandId: "3", imgUrl: "images/brand3.png" },
      { brandId: "4", imgUrl: "images/brand4.png" },
      { brandId: "5", imgUrl: "images/brand4.png" },
    ],
    companyProducts: [
      {
        imgUrl: "images/lg/lg1.png",
      },
      {
        imgUrl: "images/lg/lg2.png",
      },
      {
        imgUrl: "images/lg/lg3.png",
      },
      {
        imgUrl: "images/lg/lg4.png",
      },
    ],
  },
  reducers: {
    handleProjectGallarySelection: {
      reducer(state, action) {
        let selection = current(state).ProjectGallary;
        if (action.payload !== 0) {
          state.projectGallarySelection = selection.filter((c) => c.gallaryTypeId === action.payload).map((e) => e);
        } else {
          state.projectGallarySelection = selection;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  //   extraReducers: {
  //     [getAllBrandreq.fulfilled]: (state, action) => {
  //       state.allBrands = action.payload;
  //     },
  //   },
});

export const { handleProjectGallarySelection } = ClientHomeSlice.actions;

export default ClientHomeSlice.reducer;
