import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getAllOptions,
  DeleteOptions,
  getAllOptionValues,
  updateOptions,
  addOptionValue,
  deleteOptionValue,
} from "../../utils/options-util";
import OptionsConstant from "../../constants/OptionsConstant";

export const getAllOptionReq = createAsyncThunk("getAllOptionReq", async (optionsid) => {
  const response = await getAllOptions(optionsid);
  return { optionsid, response };
});

export const getAllOptionValueReq = createAsyncThunk("getAllOptionValueReq", async (optionsid) => {
  const response = await getAllOptionValues(optionsid);
  return response;
});

export const disableValueReq = createAsyncThunk("disableValueReq", async (req) => {
  return req;
});

export const updateOptionReq = createAsyncThunk("updateOptionReq", async (req) => {
  let typeid = 0;
  if (req.TypeId !== undefined) {
    typeid = req.TypeId.value;
  }
  const response = await updateOptions(req.OptionId, req.OptionName, typeid, req.IsRequired, req.ProductId);
  return response;
});

export const addOptionValueReq = createAsyncThunk("addOptionValueReq", async (req) => {
  let pricetype = 0;
  if (req.pricetype !== undefined) {
    pricetype = req.pricetype.value;
  }
  const response = await addOptionValue(req.optionId, req.label, req.price, pricetype);
  return response;
});

const OptionSlice = createSlice({
  name: "options",
  initialState: {
    allOptions: [],
    allOptionsTypes: [
      {
        label: "Text",
        options: [
          {
            label: "Field",
            value: 1,
          },
          {
            label: "Textarea",
            value: 2,
          },
        ],
      },
      {
        label: "Select",
        options: [
          {
            label: "Dropdown",
            value: 3,
          },
          {
            label: "Checkbox",
            value: 4,
          },
          {
            label: "Custom Checkbox",
            value: 5,
          },
          {
            label: "Radio Button",
            value: 6,
          },
          {
            label: "Custom Radio Button",
            value: 7,
          },
          {
            label: "Multi Select",
            value: 8,
          },
        ],
      },
      {
        label: "Date",
        options: [
          {
            label: "Date",
            value: 9,
          },
          {
            label: "Date & Time",
            value: 10,
          },
          {
            label: "Time",
            value: 11,
          },
        ],
      },
    ],
    OptionType: [
      { label: "Fixed", value: "1" },
      { label: "Percent", value: "2" },
    ],
    selectedOptionId: 0,
    selectedOptionValueId: 0,

    generalTabSelected: true,
    optionTabSelection: false,
    txtOptionName: "",
    optionTypeSelection: { label: "Fixed", value: "1" },
    isRequired: false,
    disableValueTab: true,
    allOptionValue: [],
    optionValueTypeSelection: undefined,
    txtOptionValueLabel: "",
    txtOptionValuePrice: "",
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        state.optionTabSelection = false;
        switch (action.payload) {
          case OptionsConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          case OptionsConstant.VALUE:
            state.optionTabSelection = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleResetOptions: {
      reducer(state, action) {
        state.selectedOptionId = 0;

        state.generalTabSelected = true;
        state.optionTabSelection = false;
        state.txtOptionName = "";
        state.isRequired = false;
        state.disableValueTab = true;
        state.allOptionValue = [];
        state.optionTypeSelection = undefined;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleTxtOptionName: {
      reducer(state, action) {
        state.txtOptionName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    HandleOptionTypeSelection: {
      reducer(state, action) {
        state.optionTypeSelection = action.payload.OptionSelection;
      },
      prepare(OptionSelection) {
        return {
          payload: { OptionSelection },
        };
      },
    },
    HandleOptionTypeValueSelection: {
      reducer(state, action) {
        state.optionValueTypeSelection = action.payload.OptionSelection;
      },
      prepare(OptionSelection) {
        return {
          payload: { OptionSelection },
        };
      },
    },
    handleIsFilterable: {
      reducer(state, action) {
        if (state.isRequired) {
          state.isRequired = false;
        } else {
          state.isRequired = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOptionValueLabel: {
      reducer(state, action) {
        state.txtOptionValueLabel = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOptionPriceLabel: {
      reducer(state, action) {
        state.txtOptionValuePrice = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/[^0-9]/g, ""),
        };
      },
    },
    handleDeleteAttribute: {
      reducer(state, action) {},
      prepare(e) {
        DeleteOptions(e);
        return {
          payload: e,
        };
      },
    },
    // handleResetBrand: {
    //   reducer(state, action) {
    //     state.selectedBrandId = 0;

    //     state.generalTabSelected = true;
    //     state.txtBrandName = "";
    //     state.IsEnableBrand = true;

    //     state.imagesTabSelected = false;
    //     state.brandImgPath = "/images/Default/product.png";
    //     state.brandBannerPath = "/images/Default/product.png";

    //     state.seoTabSelected = false;
    //     state.txtSeoUrl = "";
    //     state.txtMetaTitle = "";
    //     state.txtMetaDesc = "";
    //   },
    //   prepare(e) {
    //     return {
    //       payload: e,
    //     };
    //   },
    // },
    handleDeleteOptions: {
      reducer(state, action) {},
      prepare(e) {
        DeleteOptions(e);
        return {
          payload: e,
        };
      },
    },
    handleDeleteOptionValues: {
      reducer(state, action) {},
      prepare(e) {
        deleteOptionValue(e);
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [getAllOptionReq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        let optionType = current(state).allOptionsTypes;
        if (action.payload.optionsid === 0) {
          const getOptionsName = (optionId) => {
            let name = "";
            optionType.forEach((e) => {
              e.options.forEach((op) => {
                if (op.value === optionId) {
                  name = op.label;
                  return;
                }
              });
            });
            return name;
          };

          let myJson = [];
          action.payload.response.forEach((e) => {
            myJson.push({ ...e, typeId: getOptionsName(e.typeId) });
          });

          state.allOptions = myJson;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedOptionId = action.payload.response[0].optionId;
            state.isRequired = action.payload.response[0].isRequired;
            state.txtOptionName = action.payload.response[0].optionName;

            optionType.forEach((e) => {
              // if (e.value === action.payload.response[0].typeId.toString()) {
              //   state.optionTypeSelection = e;
              // }
              e.options.forEach((op) => {
                if (op.value === action.payload.response[0].typeId) {
                  state.optionTypeSelection = op;
                }
              });
            });
          }
        }
      }
    },
    [disableValueReq.fulfilled]: (state, action) => {
      state.disableValueTab = action.payload;
    },
    [getAllOptionValueReq.fulfilled]: (state, action) => {
      let myJson = [];
      if (action.payload !== false) {
        action.payload.forEach((e) => {
          if (e.priceType === 1) {
            myJson.push({ ...e, priceType: "Fixed" });
          } else {
            myJson.push({ ...e, priceType: "Percent" });
          }
        });
      }
      state.allOptionValue = myJson;
    },
    [updateOptionReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.selectedOptionId = action.payload;
        state.disableValueTab = false;
      }
    },
  },
});

export const {
  handleTabSelection,
  handleTxtOptionName,
  HandleOptionTypeSelection,
  handleResetOptions,
  handleIsFilterable,
  handleOptionValueLabel,
  handleOptionPriceLabel,
  //   handleBannerFileUpload,
  //   handleIsEnableBrand,
  //   handleTxtBrandName,
  //   handleTxtSeoUrl,
  //   handleMetaTitle,
  //   handleMetaDesc,
  //   handleResetBrand,
  handleDeleteOptions,
  HandleOptionTypeValueSelection,
  handleDeleteOptionValues,
} = OptionSlice.actions;

export default OptionSlice.reducer;
