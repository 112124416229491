import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getAllUsers,
  GetCountries,
  GetStates,
  GetCities,
  GetAllAddress,
  UpdateAddress,
  DeleteUserAddress,
  UpdateUserDetails,
} from "../../utils/user-util";
import { DEFAULT_PROFILE_PIC } from "../../constants/pathConstant";

export const getAllUsersreq = createAsyncThunk("allUsers", async (userid) => {
  const response = await getAllUsers(userid);
  return { response, userid };
});

export const getAllCountriesreq = createAsyncThunk("getAllCountriesreq", async () => {
  const response = await GetCountries();
  return response;
});

export const getAllStatereq = createAsyncThunk("getAllStatereq", async (countryid) => {
  const response = await GetStates(countryid);
  return response;
});

export const getAllCityreq = createAsyncThunk("getAllCityreq", async (stateid) => {
  const response = await GetCities(stateid);
  return response;
});

export const getAllAddressReq = createAsyncThunk("getAllAddressReq", async () => {
  const response = await GetAllAddress();
  return response;
});

export const updateAddressReq = createAsyncThunk("updateAddressReq", async (req) => {
  const response = await UpdateAddress(req);
  return response;
});

export const deleteAddressReq = createAsyncThunk("deleteAddressReq", async (addressid) => {
  const response = await DeleteUserAddress(addressid);
  return response;
});

export const udpateUserProfileReq = createAsyncThunk("udpateUserProfileReq", async (req) => {
  const response = await UpdateUserDetails(req);
  return response;
});

const UserSlice = createSlice({
  name: "user",
  initialState: {
    allUsers: [],
    allAddress: [],
    allCountries: [],
    countrySelection: undefined,
    allStates: [],
    stateSelection: undefined,
    allCities: [],
    citySelection: undefined,
    addFullname: "",
    adddressFull: "",
    pincode: "",
    addressid: 0,
    addressMobile: "",

    // user profile
    userDetails: {},
    userFirstName: "",
    userMiddleName: "",
    userLastName: "",
    userProfilePic: DEFAULT_PROFILE_PIC,
    userMobile: "",
    userEmail: "",
  },
  reducers: {
    handleOnChangeCountrySelection: {
      reducer(state, action) {
        state.countrySelection = action.payload;
        state.stateSelection = undefined;
        state.citySelection = undefined;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOnChangeStateSelection: {
      reducer(state, action) {
        state.stateSelection = action.payload;
        state.citySelection = undefined;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOnChangeCitySelection: {
      reducer(state, action) {
        state.citySelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleOnChangeAddressName: {
      reducer(state, action) {
        state.addFullname = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeAddress: {
      reducer(state, action) {
        state.adddressFull = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangePincode: {
      reducer(state, action) {
        state.pincode = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/\D/, ""),
        };
      },
    },
    handleOnChangeUserFirstname: {
      reducer(state, action) {
        state.userFirstName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeUserMiddlename: {
      reducer(state, action) {
        state.userMiddleName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeUserLastname: {
      reducer(state, action) {
        state.userLastName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleOnChangeAddressMobile: {
      reducer(state, action) {
        state.addressMobile = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/\D/, ""),
        };
      },
    },
    handleClearAddressSelection: {
      reducer(state, action) {
        state.countrySelection = undefined;
        state.stateSelection = undefined;
        state.citySelection = undefined;
        state.addFullname = "";
        state.adddressFull = "";
        state.pincode = "";
        state.addressid = 0;
        state.addressMobile = "";
      },
      prepare() {
        return {
          payload: null,
        };
      },
    },
    handleEditAddressSelection: {
      reducer(state, action) {
        let allAddress = current(state).allAddress;
        if (allAddress !== undefined) {
          let address = allAddress.filter((c) => c.addressId === action.payload)[0];
          state.addressid = address.addressId;
          state.countrySelection = JSON.parse(address.countrySelection)[0];
          state.stateSelection = JSON.parse(address.stateSelection)[0];
          state.citySelection = JSON.parse(address.citySelection)[0];
          state.addFullname = address.fullName;
          state.adddressFull = address.address;
          state.pincode = address.pincode;
          state.addressMobile = address.mobile;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [getAllUsersreq.fulfilled]: (state, action) => {
      if (action.payload !== false && action.payload.userid === 0) {
        state.allUsers = action.payload.response;
      }
      if (action.payload !== false && action.payload.userid !== 0) {
        if (action.payload.response.length > 0) {
          state.userDetails = action.payload.response[0];
          state.userFirstName = action.payload.response[0].firstName;
          state.userMiddleName = action.payload.response[0].middleName;
          state.userLastName = action.payload.response[0].lastName;
          state.userProfilePic = action.payload.response[0].profilePic;
          state.userMobile = action.payload.response[0].mobile;
          state.userEmail = action.payload.response[0].email;
        }
      }
    },
    [getAllCountriesreq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          myJson.push({ label: e.countryName, value: e.countryId });
        });
        state.allCountries = myJson;
      }
    },
    [getAllStatereq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          myJson.push({ label: e.stateName, value: e.stateId });
        });
        state.allStates = myJson;
      }
    },
    [getAllCityreq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          myJson.push({ label: e.cityName, value: e.cityId });
        });
        state.allCities = myJson;
      }
    },
    [getAllAddressReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.allAddress = action.payload;
      }
    },
    [updateAddressReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        // state.allAddress = action.payload;
      }
    },
    [deleteAddressReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        // state.allAddress = action.payload;
      }
    },
  },
});

export const {
  handleOnChangeCountrySelection,
  handleOnChangeStateSelection,
  handleOnChangeCitySelection,
  handleOnChangeAddressName,
  handleOnChangeAddress,
  handleOnChangePincode,
  handleOnChangeAddressMobile,
  handleClearAddressSelection,
  handleEditAddressSelection,
  handleOnChangeUserFirstname,
  handleOnChangeUserMiddlename,
  handleOnChangeUserLastname,
} = UserSlice.actions;

export default UserSlice.reducer;
