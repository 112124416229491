import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getAllCategories, UpdateCategory, deleteCategory } from "../../utils/category-util";
import { getAllBrands } from "../../utils/brand-util";
import { getAllTags } from "../../utils/Tags-util";
import { RemoveProductPDf } from "../../utils/product-util";
import {
  GetAllProducts,
  GetAttributesAndSetValues,
  UpdateProduct,
  GetProductDetails,
  AddProductImages,
  DeleteProductImage,
  DeleteProduct,
  UpdateRalatedProduct,
  UpdateCrossSellProduct,
  UpdateUpSellProduct,
  UpdateProductAttrAndValues,
  GetProductAttrValues,
  RemoevProductAttrAndValues,
  GetProductAttrAndValuesForClient,
  GetAllGovTaxCalss,
} from "../../utils/product-util";
import { getAllOptions, getAllOptionValues } from "../../utils/options-util";
import CatalogConstant from "../../constants/CatalogConstant";
import pathConstant from "../../constants/pathConstant";
var dateFormat = require("dateformat");

export const updateProductReq = createAsyncThunk("updateProductReq", async (req) => {
  const response = await UpdateProduct(req);
  return response;
});

export const ddlBrandReq = createAsyncThunk("ddlBrand", async (brandid) => {
  const response = await getAllBrands(brandid);
  return response;
});

export const ddlAllTagsReq = createAsyncThunk("ddlAllTags", async (tagid) => {
  const response = await getAllTags(tagid);
  return response;
});

export const getAllProductReq = createAsyncThunk("getAllProductReq", async () => {
  const response = await GetAllProducts();
  return response;
});

export const getProductDetailsReq = createAsyncThunk("getProductDetailsReq", async (productid) => {
  const response = await GetProductDetails(productid);
  return response;
});

export const getAttributesAndSetValuesReq = createAsyncThunk("getAttributesAndSetValuesReq", async () => {
  const response = await GetAttributesAndSetValues();
  return response;
});

export const updateProductAttrAndValuesReq = createAsyncThunk("updateProductAttrAndValuesReq", async (req) => {
  const response = await UpdateProductAttrAndValues(req);
  return response;
});

export const getProductAttrValuesReq = createAsyncThunk("getProductAttrValuesReq", async (req) => {
  const response = await GetProductAttrValues(req);
  return { response, req };
});

export const remoevProductAttrAndValuesReq = createAsyncThunk("remoevProductAttrAndValuesReq", async (attrid) => {
  const response = await RemoevProductAttrAndValues(attrid);
  return response;
});

export const getProductAttrAndValuesForClientReq = createAsyncThunk(
  "getProductAttrAndValuesForClientReq",
  async (pid) => {
    const response = await GetProductAttrAndValuesForClient(pid);
    return response;
  }
);

export const getAllGovTaxCalssReq = createAsyncThunk("getAllGovTaxCalssReq", async () => {
  const response = await GetAllGovTaxCalss();
  return response;
});

export const getAllGlobalOptionsReq = createAsyncThunk("getAllGlobalOptionsReq", async (optionid) => {
  const response = await getAllOptions(optionid);
  return response;
});

export const getAllGlobalOptionValueReq = createAsyncThunk("getAllGlobalOptionValueReq", async (optionid) => {
  const response = await getAllOptionValues(optionid);
  return { response, optionid };
});

function DiscountCal(price, specialPrice, type) {
  // Discount calculation - percentage
  let pPrice = 0;
  let specialProPrice = 0;
  let discount = 0;
  let priceAfterDiscount = 0;
  if (type === 1) {
    pPrice = price;
    priceAfterDiscount = specialPrice;
    specialProPrice = price - specialPrice;
  }

  // Discount calculation - FIXED
  if (type === 2) {
    pPrice = price;
    discount = specialPrice;
    specialProPrice = price - (price * specialPrice) / 100;
    priceAfterDiscount = price - (price - (price * specialPrice) / 100);
  }

  return { pPrice, specialProPrice, discount, priceAfterDiscount };
}

const CatalogSlice = createSlice({
  name: "catalog",
  initialState: {
    isProductDetailsLoading: true,

    selectedProductId: 0,
    generalTabSelected: true,
    priceTabSelected: false,
    inventoryTabSelected: false,
    imagesTabSelected: false,
    downloadTabSelected: false,
    seoTabSelected: false,
    attributeTabSelected: false,
    optionsTabSelected: false,
    relatebProductSelected: false,
    upSalsesTabSelected: false,
    crossTabSelected: false,
    additionalTabSelected: false,
    isInWishlist: false,

    lstBrands: [],
    lstCategories: [],
    lstTags: [],
    taxClass: [],
    lstAllProducts: [],
    productPdfPath: "",

    productName: "",
    productDesc: "",
    brandSelection: [],
    categoeySelection: undefined,
    // taxClassSelection: { label: "GST", value: 1 },
    taxClassSelection: undefined,

    isProductShipped: true,
    enableProduct: true,
    isEnquiryOnly: false,
    tagSelections: [],
    tagSelectionsClientList: "",

    productPrice: undefined,
    specialPrice: undefined,
    specialPriceStart: new Date(),
    specialPriceEnd: new Date(),

    productCoverImgPath: pathConstant.DEFAULT_IMG_PATH,
    productMoreImagesPath: pathConstant.DEFAULT_IMG_PATH,
    multipleImages: [],
    categorySelection: [],

    // inventory management
    txtSKU: "",
    txtInventoryQty: 0,
    lstInventoryManagement: [
      { value: false, label: "Don't Track Inventory" },
      { value: true, label: "Track Inventory" },
    ],
    lstInvestoryManagementSelection: { value: false, label: "Don't Track Inventory" },
    lstStockAvailability: [
      { value: false, label: "In Stock" },
      { value: true, label: "Out of Stock" },
    ],
    lstStockAvailabilitySelection: { value: true, label: "In Stock" },
    lstProductAttrSelection: [],

    // Additional
    shortDescription: "",
    productNewFrom: new Date(),
    productNewTo: new Date(),

    // Attributes
    attributeSelection: undefined,
    allAttributesAndValues: [],
    attributeValueSelection: [],

    // SEO
    txtSeoUrl: "",
    txtMetaTitle: "",
    txtMetaDesc: "",

    // Optinos
    lstGlobelOptions: [],
    lstGlobelOptionSelection: undefined,
    lstProductGlobelOptionSelection: [],

    optionTypeId: 1,
    isUnderSpecialPrice: false,
    productPriceDiscountPer: 0,
    productPriceAfterDiscount: 0,
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        state.priceTabSelected = false;
        state.inventoryTabSelected = false;
        state.imagesTabSelected = false;
        state.downloadTabSelected = false;
        state.seoTabSelected = false;
        state.attributeTabSelected = false;
        state.optionsTabSelected = false;
        state.relatebProductSelected = false;
        state.upSalsesTabSelected = false;
        state.crossTabSelected = false;
        state.additionalTabSelected = false;
        switch (action.payload) {
          case CatalogConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          case CatalogConstant.PRICE:
            state.priceTabSelected = true;
            break;
          case CatalogConstant.INVENTORY:
            state.inventoryTabSelected = true;
            break;
          case CatalogConstant.IMAGES:
            state.imagesTabSelected = true;
            break;
          case CatalogConstant.DOWNLOAD:
            state.downloadTabSelected = true;
            break;
          case CatalogConstant.SEO:
            state.seoTabSelected = true;
            break;
          case CatalogConstant.ATTRIBUTES:
            state.attributeTabSelected = true;
            break;
          case CatalogConstant.OPTIONS:
            state.optionsTabSelected = true;
            break;
          case CatalogConstant.RELATED_PRODUCTS:
            state.relatebProductSelected = true;
            break;
          case CatalogConstant.UP_SELLS:
            state.upSalsesTabSelected = true;
            break;
          case CatalogConstant.CROSS_SELLS:
            state.crossTabSelected = true;
            break;
          case CatalogConstant.ADDITIONAL:
            state.additionalTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleProductName: {
      reducer(state, action) {
        state.productName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtSKU: {
      reducer(state, action) {
        state.txtSKU = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleProductPrice: {
      reducer(state, action) {
        state.productPrice = action.payload;
      },
      prepare(e) {
        return {
          // payload: e.target.value.replace(/[^0-9]/g, ""),
          payload: e.target.value,
        };
      },
    },
    handleSpecialPrice: {
      reducer(state, action) {
        state.specialPrice = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
          // payload: e.target.value.replace(/[^0-9]/g, ""),
        };
      },
    },
    handleSpecialPriceStart: {
      reducer(state, action) {
        state.specialPriceStart = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleSpecialPriceEnd: {
      reducer(state, action) {
        state.specialPriceEnd = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleProductDesc: {
      reducer(state, action) {
        state.productDesc = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleBrandSelection: {
      reducer(state, action) {
        state.brandSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handletaxClassSelection: {
      reducer(state, action) {
        state.taxClassSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTagSelection: {
      reducer(state, action) {
        state.tagSelections = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleEnableProduct: {
      reducer(state, action) {
        if (state.enableProduct) {
          state.enableProduct = false;
        } else {
          state.enableProduct = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleIsEnquiryOnly: {
      reducer(state, action) {
        if (state.isEnquiryOnly) {
          state.isEnquiryOnly = false;
        } else {
          state.isEnquiryOnly = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleIsProductShipped: {
      reducer(state, action) {
        if (state.isProductShipped) {
          state.isProductShipped = false;
        } else {
          state.isProductShipped = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          state.productCoverImgPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleBannerFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          state.productMoreImagesPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleMultiple: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          if (
            state.multipleImages.includes(current(state).productMoreImagesPath) === false &&
            current(state).productMoreImagesPath !== pathConstant.DEFAULT_IMG_PATH
          ) {
            state.multipleImages.push(current(state).productMoreImagesPath);
          }
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleRemoveImageSelection: {
      reducer(state, action) {
        let currSelection = current(state).multipleImages;
        var newArray = currSelection.filter((value) => value != action.payload.img);
        state.multipleImages = newArray;
        // if (action.payload !== undefined) {
        //   if (
        //     state.multipleImages.includes(current(state).productMoreImagesPath) === false &&
        //     current(state).productMoreImagesPath !== pathConstant.DEFAULT_IMG_PATH
        //   ) {
        //     state.multipleImages.push(current(state).productMoreImagesPath);
        //   }
        // }
      },
      prepare(img, pid) {
        const data = {
          productId: pid,
          productImages: img,
        };
        DeleteProductImage(data);
        return {
          payload: { img, pid },
        };
      },
    },
    handleInventoryManagementSelection: {
      reducer(state, action) {
        state.lstInvestoryManagementSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleStockAvalablity: {
      reducer(state, action) {
        state.lstStockAvailabilitySelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    hangleProductNewFrom: {
      reducer(state, action) {
        state.productNewFrom = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtInventoryQty: {
      reducer(state, action) {
        state.txtInventoryQty = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value.replace(/[^0-9]/g, ""),
        };
      },
    },
    hangleProductNewTo: {
      reducer(state, action) {
        state.productNewTo = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleProductShortDescription: {
      reducer(state, action) {
        state.shortDescription = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleAttributeValueChange: {
      reducer(state, action) {
        state.attributeValueSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleAttributeSelection: {
      reducer(state, action) {
        state.attributeValueSelection = [];
        state.attributeSelection = action.payload;
        state.attributeValueSelection = [];
        state.allAttributeValue = [];
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleSubmitProductImages: {
      reducer(state, action) {},
      prepare(e) {
        AddProductImages(e);
        return {
          payload: e,
        };
      },
    },
    handleResetProductSelection: {
      reducer(state, action) {
        state.selectedProductId = 0;
        state.productName = "";
        state.productDesc = "";

        state.brandSelection = undefined;
        state.taxClassSelection = { label: "GST", value: 1 };
        state.isEnquiryOnly = false;
        state.isProductShipped = true;
        state.enableProduct = false;
        state.productPrice = 0;
        state.specialPrice = 0;
        state.txtSKU = "";
        state.txtInventoryQty = 0;
        state.productCoverImgPath = pathConstant.DEFAULT_IMG_PATH;
        state.txtSeoUrl = "";
        state.txtMetaTitle = "";
        state.txtMetaDesc = "";
        state.shortDescription = "";
        state.tagSelections = [];
        state.multipleImages = [];
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleResetProductId: {
      reducer(state, action) {
        state.selectedProductId = 0;
      },
      prepare() {
        return {
          payload: {},
        };
      },
    },
    handleCategoryOcChangeSelection: {
      reducer(state, action) {
        let myJson = [];
        action.payload.categorySelection.forEach((e) => {
          myJson.push({ value: e.value, label: e.label.split("|--").join("") });
        });

        state.categorySelection = myJson;
      },
      prepare(categorySelection) {
        return {
          payload: { categorySelection },
        };
      },
    },
    handleDeleteProduct: {
      reducer(state, action) {},
      prepare(productId) {
        DeleteProduct(productId);
        return {
          payload: {},
        };
      },
    },
    handleTxtSeoUrl: {
      reducer(state, action) {
        state.txtSeoUrl = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaTitle: {
      reducer(state, action) {
        state.txtMetaTitle = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaDesc: {
      reducer(state, action) {
        state.txtMetaDesc = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleRelatedProductOnChecked: {
      reducer(state, action) {
        // state.txtMetaDesc = action.payload;
      },
      prepare(e, productid, relatedProductid, listfor) {
        if (listfor === "CrossSell") {
          UpdateCrossSellProduct(productid, relatedProductid, e.target.checked);
        }
        if (listfor === "UpsSell") {
          UpdateUpSellProduct(productid, relatedProductid, e.target.checked);
        }
        if (listfor === "RelatedProduct") {
          UpdateRalatedProduct(productid, relatedProductid, e.target.checked);
        }
        return {
          payload: {},
        };
      },
    },
    handleOnClickAddProductAttribute: {
      reducer(state, action) {
        let selection = current(state).lstProductAttrSelection;
        let myJson = [];
        let attr = action.payload.attr;
        let value = action.payload.value;
        myJson.push({ attr, value });
        state.lstProductAttrSelection = selection.concat(myJson);

        state.attributeSelection = [];
        state.attributeValueSelection = [];

        // state.lstProductAttrSelection = {...selection,}
      },
      prepare(attr, value) {
        return {
          payload: { attr, value },
        };
      },
    },
    handleOnChangeGlobelOption: {
      reducer(state, action) {
        state.lstGlobelOptionSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleAddGlobalSelection: {
      reducer(state, action) {
        // let selVal = current(state).lstGlobelOptionSelection;
        // state.lstProductGlobelOptionSelection = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleRemoveProductAttribute: {
      reducer(state, action) {
        // let selection = current(state).lstProductAttrSelection;
      },
      prepare(attr, value) {
        return {
          payload: { attr, value },
        };
      },
    },
    handlePdfPathSelection: {
      reducer(state, action) {
        state.productPdfPath = action.payload.path;
      },
      prepare(path) {
        return {
          payload: { path },
        };
      },
    },
    handleDeletePDFSelection: {
      reducer(state, action) {
        state.productPdfPath = "";
      },
      prepare(pid) {
        RemoveProductPDf(pid);
        return {
          payload: {},
        };
      },
    },
    handleManagedGlobalOptionValue: {
      reducer(state, action) {
        let optionSelection = current(state).lstProductGlobelOptionSelection;
        if (action.payload.optionValueId === null) {
          state.lstProductGlobelOptionSelection = optionSelection.filter(
            (c) => c.Option.value !== action.payload.optionId
          );
        } else {
          let myJson = [];
          optionSelection.forEach((e) => {
            let sel = e.OptionValue.filter((x) => x.optionValueId !== action.payload.optionValueId);
            if (sel.length > 0) {
              myJson.push({
                ...e,
                isOldOptionSelection: false,
                OptionValue: sel,
              });
            }
          });
          state.lstProductGlobelOptionSelection = myJson;
        }
      },
      prepare(optionId, optionValueId) {
        return {
          payload: { optionId, optionValueId },
        };
      },
    },
    handleUpdateOptionSelection: {
      reducer(state, action) {
        let optSelArr = current(state).lstProductGlobelOptionSelection;
        let myJson = [];
        optSelArr.forEach((op) => {
          if (op.Option.value === action.payload.optionid) {
            let opChange = { ...op.Option, label: action.payload.ele.target.value };
            myJson.push({ ...op, Option: opChange });
          } else if (action.payload.optionValId !== 0) {
            let myJsonVal = [];
            op.OptionValue.forEach((opv) => {
              if (opv.optionValueId === action.payload.optionValId) {
                if (action.payload.type === "OptionValLabel") {
                  myJsonVal.push({ ...opv, label: action.payload.ele.target.value });
                }
                if (action.payload.type === "OptionValPrice") {
                  myJsonVal.push({ ...opv, price: action.payload.ele.target.value.replace(/\D/, "") });
                }
                if (action.payload.type === "OptionValType") {
                  myJsonVal.push({ ...opv, priceType: action.payload.ele.value });
                }
              } else {
                myJsonVal.push(opv);
              }
            });
            myJson.push({ ...op, OptionValue: myJsonVal });
          } else {
            myJson.push({ ...op });
          }
        });
        state.lstProductGlobelOptionSelection = myJson;
      },
      prepare(optionid, optionValId, ele, type) {
        return {
          payload: { optionid, optionValId, ele, type },
        };
      },
    },
    handleClientOptionOnChnage: {
      reducer(state, action) {
        console.log(action.payload);
        let currProductPrice = current(state).productPrice;
        let currSpecialPrice = current(state).specialPrice;
        let diffPrice = action.payload.price - currProductPrice;

        if (action.payload.type === "checkbox") {
          if (action.payload.ele.target.checked) {
            state.productPrice = parseInt(currProductPrice) + parseInt(action.payload.price);
            state.specialPrice = parseInt(currSpecialPrice) + parseInt(action.payload.price);
          } else {
            state.productPrice = parseInt(currProductPrice) - parseInt(action.payload.price);
            state.specialPrice = parseInt(currSpecialPrice) - parseInt(action.payload.price);
          }
        }
        if (action.payload.type === "radio") {
          state.productPrice = currProductPrice + diffPrice;
          state.specialPrice = currSpecialPrice + diffPrice;

          // const { pPrice, specialProPrice, discount, priceAfterDiscount } = DiscountCal(
          //   currProductPrice,
          //   parseInt(action.payload.price),
          //   action.payload.pricetype
          // );

          // state.productPrice = pPrice;
          // state.productPriceDiscountPer = discount;
          // state.specialPrice = specialProPrice;
          // state.productPriceAfterDiscount = priceAfterDiscount;
        }
      },
      prepare(ele, price, type, pricetype) {
        return {
          payload: { ele, price, type, pricetype },
        };
      },
    },
  },
  extraReducers: {
    [ddlBrandReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          myJson.push({ label: e.brandName, value: e.brandId });
        });
        state.lstBrands = myJson;
      }
    },
    [ddlAllTagsReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          myJson.push({ label: e.tagName, value: e.tagId });
        });
        state.lstTags = myJson;
      }
    },
    [getAllProductReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload !== false) {
        state.lstAllProducts = action.payload;
      }
    },
    [getAttributesAndSetValuesReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload !== false) {
        let lstUnique = action.payload
          .map((value) => value.attrSetsId)
          .filter((value, index, _arr) => _arr.indexOf(value) == index);
        let myJson = [];
        lstUnique.forEach((e) => {
          let childArray = [];
          let labelAttrSet = "";
          let childCollection = action.payload.filter((c) => c.attrSetsId === e);
          childCollection.forEach((child) => {
            labelAttrSet = child.attrSetsName;
            childArray.push({
              label: child.attrName,
              value: child.attrId,
            });
          });
          myJson.push({ label: labelAttrSet, options: childArray });
        });
        state.allAttributesAndValues = myJson;
      }
    },
    [getProductDetailsReq.fulfilled]: (state, action) => {
      if (action.payload !== false && action.payload.length > 0) {
        let pDetails = action.payload[0];
        state.selectedProductId = pDetails.productId;
        state.productName = pDetails.productName;
        state.productDesc = pDetails.description;

        if (pDetails.brand != "") {
          state.brandSelection = JSON.parse(pDetails.brand)[0];
        }
        if (pDetails.txtCalass != "") {
          state.taxClassSelection = JSON.parse(pDetails.txtCalass)[0];
        }
        state.isEnquiryOnly = pDetails.isEnquiryOnly;
        state.isProductShipped = pDetails.isProductShiped;
        state.enableProduct = pDetails.isEnable;
        state.productPrice = pDetails.price;
        state.specialPrice = pDetails.specialPrice;

        // set if under special price
        let currentDate = dateFormat(new Date(), "yyyy-mm-dd");
        let specialPriceStartDate = dateFormat(pDetails.specialPriceStart, "yyyy-mm-dd");
        let specialPriceEndDate = dateFormat(pDetails.specialPriceEnd, "yyyy-mm-dd");
        state.specialPriceStart = specialPriceStartDate;
        state.specialPriceEnd = specialPriceEndDate;
        if (currentDate > specialPriceStartDate && currentDate < specialPriceEndDate) {
          state.isUnderSpecialPrice = true;

          // finding option type 1 = fixed 2 = percentage
          if (pDetails.specialPriceType !== "") {
            let opn = JSON.parse(pDetails.specialPriceType);
            state.optionTypeSelection = opn;
            if (opn.length > 0) {
              state.optionTypeId = opn[0].value;

              const { pPrice, specialProPrice, discount, priceAfterDiscount } = DiscountCal(
                pDetails.price,
                pDetails.specialPrice,
                opn[0].value
              );

              state.productPrice = pPrice;
              state.productPriceDiscountPer = discount;
              state.specialPrice = specialProPrice;
              state.productPriceAfterDiscount = priceAfterDiscount.toFixed(2);
            } else {
              state.optionTypeId = 1;
            }
          }
        } else {
          state.isUnderSpecialPrice = false;
        }

        state.txtSKU = pDetails.sku;
        state.categoeySelection = "Add Category*****";
        state.tagSelectionsClientList = "Tag selection******";
        state.productPdfPath = pDetails.pdfPath;
        state.isInWishlist = pDetails.myWishlistCount > 0 ? true : false;

        let lstInventory = current(state).lstInventoryManagement;
        if (pDetails.inventoryManagement === 0) {
          state.lstInvestoryManagementSelection = lstInventory[0];
        } else {
          state.lstInvestoryManagementSelection = lstInventory[1];
        }

        let lstSAvailability = current(state).lstStockAvailability;
        if (pDetails.inventoryQty === 0) {
          state.lstStockAvailabilitySelection = lstSAvailability[0];
        } else {
          state.lstStockAvailabilitySelection = lstSAvailability[1];
        }

        state.txtInventoryQty = pDetails.inventoryQty;
        state.productCoverImgPath = pDetails.img;
        state.txtSeoUrl = pDetails.seoUrl;
        state.txtMetaTitle = pDetails.metaTitle;
        state.txtMetaDesc = pDetails.metaDescription;
        state.shortDescription = pDetails.shortDescription;
        state.productNewFrom = dateFormat(pDetails.productNewFrom, "yyyy-mm-dd");
        state.productNewTo = dateFormat(pDetails.productNewTo, "yyyy-mm-dd");
        if (pDetails.productTags !== "") {
          state.tagSelections = JSON.parse(pDetails.productTags);
        }
        if (pDetails.productCategories !== "") {
          const catSelection = JSON.parse(pDetails.productCategories);
          state.categorySelection = catSelection;
        }
        if (pDetails.productImages !== "") {
          state.multipleImages = JSON.parse(pDetails.productImages).map((c) => c.Img);
        }
        state.isProductDetailsLoading = false;

        // options
        let myOptionJson = [];
        if (pDetails.productOptions !== "") {
          let optionSelection = JSON.parse(pDetails.productOptions);
          optionSelection.forEach((e) => {
            let op = { value: e.OptionId, label: e.Name, typeId: e.TypeId, isOldOptionSelection: true };
            let opValJson = [];
            if (e.OptionValues !== null && e.OptionValues !== undefined) {
              e.OptionValues.forEach((opv) => {
                opValJson.push({
                  optionValueId: opv.OptionValueId,
                  optionId: opv.OptionId,
                  label: opv.Label,
                  price: opv.Price,
                  priceType: opv.PriceType,
                });
              });
            }
            myOptionJson.push({ Option: op, OptionValue: opValJson });
          });
        }
        state.lstProductGlobelOptionSelection = myOptionJson;
      }
    },
    [updateProductReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.selectedProductId = action.payload;
      }
    },
    [updateProductAttrAndValuesReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        // state.selectedProductId = action.payload;
      }
    },
    [remoevProductAttrAndValuesReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        // state.selectedProductId = action.payload;
      }
    },
    [getProductAttrAndValuesForClientReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        let unique = action.payload.map((c) => c.attrSetsName).filter((v, i, a) => a.indexOf(v) === i);

        unique.forEach((atrName) => {
          let dd = action.payload.filter((c) => c.attrSetsName === atrName);
          let updateJson = [];
          dd.forEach((e) => {
            let attr = JSON.parse(e.attribute);
            let value = JSON.parse(e.attrValue);
            updateJson.push({ name: e.attrSetsName, attr, value });
          });
          myJson.push({ name: atrName, selection: updateJson });
        });

        // action.payload.forEach((e) => {
        //   let attr = JSON.parse(e.attribute);
        //   let value = JSON.parse(e.attrValue);
        //   myJson.push({ name: e.attrSetsName, attr, value });
        // });

        state.lstProductAttrSelection = myJson;
      }
    },
    [getProductAttrValuesReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        if (action.payload.req.AttrId === 0) {
          let myJson = [];
          action.payload.response.forEach((e) => {
            let attr = e.attribute !== "" && e.attribute !== null ? JSON.parse(e.attribute) : [];
            let value = e.attrValue !== "" && e.attrValue !== null ? JSON.parse(e.attrValue) : [];
            myJson.push({ id: e.id, attr, value });
          });
          state.lstProductAttrSelection = myJson;
          state.attributeSelection = [];
          state.attributeValueSelection = [];
        } else {
          if (action.payload.response.length > 0) {
            state.attributeSelection = JSON.parse(action.payload.response[0].attribute)[0];
            state.attributeValueSelection = JSON.parse(action.payload.response[0].attrValue);
          }
        }
      }
    },
    [getAllGovTaxCalssReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.taxClass = action.payload;
      }
    },
    [getAllGlobalOptionsReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        action.payload.forEach((e) => {
          console.log(e);
          myJson.push({ value: e.optionId, label: e.optionName, typeId: e.typeId });
        });
        state.lstGlobelOptions = myJson;
      }
    },
    [getAllGlobalOptionValueReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        let myJson = [];
        let oldValue = current(state).lstProductGlobelOptionSelection;
        let lstgOptions = current(state).lstGlobelOptions;
        let curOption = lstgOptions.filter((c) => c.value === action.payload.optionid);
        if (oldValue.length > 0) {
          oldValue.forEach((x) => {
            myJson.push(x);
          });
        }
        myJson.push({ OptionValue: action.payload.response, Option: curOption[0] });
        state.lstProductGlobelOptionSelection = myJson;
      }
    },
  },
});

export const {
  handleTabSelection,
  handleProductName,
  handleProductDesc,
  handleBrandSelection,
  handletaxClassSelection,
  handleTagSelection,
  handleIsProductShipped,
  handleEnableProduct,
  handleIsEnquiryOnly,
  handleProductPrice,
  handleSpecialPrice,
  handleSpecialPriceStart,
  handleSpecialPriceEnd,
  handleFileUpload,
  handleBannerFileUpload,
  handleMultiple,
  handleRemoveImageSelection,
  handleInventoryManagementSelection,
  handleStockAvalablity,
  hangleProductNewFrom,
  hangleProductNewTo,
  handleProductShortDescription,
  handleAttributeValueChange,
  handleAttributeSelection,
  handleTxtSKU,
  handleTxtInventoryQty,
  handleSubmitProductImages,
  handleResetProductSelection,
  handleDeleteProduct,
  handleCategoryOcChangeSelection,
  handleTxtSeoUrl,
  handleMetaTitle,
  handleMetaDesc,
  handleRelatedProductOnChecked,
  handleOnChecked,
  handleOnClickAddProductAttribute,
  handleRemoveProductAttribute,
  handlePdfPathSelection,
  handleDeletePDFSelection,
  handleOnChangeGlobelOption,
  handleAddGlobalSelection,
  handleManagedGlobalOptionValue,
  handleUpdateOptionSelection,
  handleClientOptionOnChnage,
  handleResetProductId
} = CatalogSlice.actions;

export default CatalogSlice.reducer;
