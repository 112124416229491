import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getPage, PageUpdate, PageDelete } from "../../utils/page-util";

export const getPageReq = createAsyncThunk("getPage", async (pageid) => {
  const response = await getPage(pageid);
  return response;
});

export const getPageDetailsReq = createAsyncThunk("getPagedetails", async (pageid) => {
  const response = await getPage(pageid);
  return response;
});

export const updatePageReq = createAsyncThunk("updatepage", async (arg) => {
  const response = await PageUpdate(arg.pageid, arg.pagename, arg.pagecontent);
  return response;
});

export const deletePageReq = createAsyncThunk("deletePageReq", async (pageid) => {
  const response = await PageDelete(pageid);
  return response;
});

const PageSlice = createSlice({
  name: "page",
  initialState: {
    pageUpdate: false,
    allPages: [],
    txtPageid: 0,
    txtPageName: "",
    txtPageContent: "",
    pageDetails: "",
  },
  reducers: {
    handleTxtPageName: {
      reducer(state, action) {
        state.txtPageName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleTxtPageContent: {
      reducer(state, action) {
        state.txtPageContent = action.payload;
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleEditPage: {
      reducer(state, action) {
        const allPage = current(state).allPages;
        const selectedPage = allPage.filter((e) => e.pageId === action.payload);
        if (selectedPage !== undefined) {
          state.txtPageid = selectedPage[0].pageId;
          state.txtPageName = selectedPage[0].pageName;
          state.txtPageContent = selectedPage[0].pageContent;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handlePageReset: {
      reducer(state, action) {
        state.txtPageid = 0;
        state.txtPageName = "";
        state.txtPageContent = "";
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleDeletePage: {
      reducer(state, action) {
        state.txtPageid = 0;
        state.txtPageName = "";
        state.txtPageContent = "";
      },
      prepare(e) {
        PageDelete(e);
        return {
          payload: e,
        };
      },
    },
    handleGetPageDetails: {
      reducer(state, action) {
        const allPage = current(state).allPages;
        const selectedPage = allPage.filter((e) => e.pageId === action.payload.pageid);
        if (selectedPage !== undefined) {
          state.pageDetails = selectedPage[0].pageContent;
        }
      },
      prepare(e) {
        return {
          payload: { pageid: e },
        };
      },
    },
  },
  extraReducers: {
    [getPageReq.fulfilled]: (state, action) => {
      state.allPages = action.payload;
    },
    [getPageDetailsReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload.length > 0) {
        state.pageDetails = action.payload[0].pageContent;
        state.txtPageContent = action.payload[0].pageContent;
      }
    },
    [updatePageReq.fulfilled]: (state, action) => {
      if (action.payload !== null && action.payload.isSuceess) {
        state.pageUpdate = action.payload.isSuceess;
        state.txtPageid = 0;
        state.txtPageName = "";
        state.txtPageContent = "";
      }
    },
  },
});

export const {
  handleTxtPageName,
  handleTxtPageContent,
  handleEditPage,
  handlePageReset,
  handleDeletePage,
  handleGetPageDetails,
} = PageSlice.actions;

export default PageSlice.reducer;
