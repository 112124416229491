import axios from "axios";
import config from "../config/server-config";

export const getAllAttributes = async (attrId) => {
  let url = config.getAllAttributes;
  if (attrId !== 0) {
    url = config.getAttribute;
  }
  try {
    let response = await axios.post(url, {
      AttrId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updateAttribute = async (req) => {
  console.log("reqreqreq", req);
  try {
    let response = await axios.post(config.updateAttributes, {
      AttrId: req.attrId,
      AttrSetId: req.attrSetId,
      Name: req.name,
      CatId: req.catIds,
      URL: req.url,
      IsSearchable: req.isSearchable,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getAllAttributesSet = async (attrId) => {
  try {
    let response = await axios.post(config.getAllAttributeSet, {
      AttrSetsId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const deleteAttribute = async (attrId) => {
  try {
    let response = await axios.post(config.deleteAttribute, {
      AttrId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const deleteAttributeSet = async (attrId) => {
  try {
    let response = await axios.post(config.deleteAttrSet, {
      AttrSetsId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetAllAttrValue = async (attrId) => {
  try {
    let response = await axios.post(config.getAllAttrValue, {
      AttrId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateAttrValue = async (req) => {
  try {
    let response = await axios.post(config.updateAttributeValue, {
      ValueId: 0,
      AttrId: parseInt(req.attrId),
      AttValues: req.attrValue,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const DeleteAttrValue = async (attrId) => {
  try {
    let response = await axios.post(config.deleteAttributeValue, {
      AttrId: parseInt(attrId),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const UpdateAttrSetReq = async (req) => {
  try {
    let response = await axios.post(config.updateAttrSet, {
      AttrSetsId: req.attrSetId,
      AttrSetsName: req.attrSetName,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const getAttributeForIndex = async (catid) => {
  try {
    let response = await axios.post(config.getAttributeForIndex, {
      CatId: catid,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const updateAttributeShortingIndex = async (req) => {
  try {
    let response = await axios.post(config.updateAttributeShortingIndex, {
      AttrId: req.attrid,
      ShortingIndex: req.index,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
