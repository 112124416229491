import { combineReducers } from "redux";
import loginReducer from "../features/LoginContainer/loginSlice";
import brandReducer from "../features/BrandContainer/BrandSlice";
import clientMainReducer from "../features/MainContainer/MainSlice";
import usersReducer from "../features/UserContainer/UserSlice";
import clienthomeReducer from "../features/ClientHomeContainer/ClientHomeSlice";
import productReducer from "../features/ProductContainer/ProductSlice";
import pageReducer from "../features/PageContainer/PageSlice";
import categoryReducer from "../features/CategoryContainer/CategorySlice";
import attributesReducer from "../features/AttributesContainer/AttributesSlice";
import attributesSetReducer from "../features/AttributeSetContainer/AttributesSetSlice";
import tagsReducer from "../features/TagContainer/TagSlice";
import optionsReducer from "../features/OptionsContainer/OptionSlice";
import CatalogReducer from "../features/CatalogContainer/CatalogSlice";
import userWishlistReducer from "../features/UserContainer/UserWishlistSlice";
import taxClassReducer from "../features/CatalogContainer/TaxClassSlice";
import orderReducer from "../features/OrderContainer/OrderSlice";

export default combineReducers({
  login: loginReducer,
  brand: brandReducer,
  clientMain: clientMainReducer,
  users: usersReducer,
  clienthome: clienthomeReducer,
  product: productReducer,
  page: pageReducer,
  category: categoryReducer,
  attribute: attributesReducer,
  attributeSet: attributesSetReducer,
  tags: tagsReducer,
  options: optionsReducer,
  catalog: CatalogReducer,
  userWishlist: userWishlistReducer,
  taxClass: taxClassReducer,
  orders: orderReducer,
});
