export const GENERAL = "General";
export const IMAGES = "Images";
export const SEO = "SEO";
export const VALUE = "Value";

export default {
  GENERAL,
  IMAGES,
  SEO,
  VALUE,
};
