import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBrands, UpdateBrand, deleteBrand } from "../../utils/brand-util";
import BrandTabConstant from "../../constants/BrandTabConstant";

export const getAllBrandreq = createAsyncThunk("getAllBrandreq", async (brandid) => {
  const response = await getAllBrands(brandid);
  return { brandid, response };
});

export const updateBrandReq = createAsyncThunk("updateBrandReq", async (req) => {
  const response = await UpdateBrand(req);
  return response;
});

const BrandSlice = createSlice({
  name: "brand",
  initialState: {
    allBrands: [],

    selectedBrandId: 0,

    generalTabSelected: true,
    txtBrandName: "",
    IsEnableBrand: true,

    imagesTabSelected: false,
    brandImgPath: "/images/Default/product.png",
    brandBannerPath: "/images/Default/product.png",

    seoTabSelected: false,
    txtSeoUrl: "",
    txtMetaTitle: "",
    txtMetaDesc: "",
  },
  reducers: {
    handleTabSelection: {
      reducer(state, action) {
        state.generalTabSelected = false;
        state.imagesTabSelected = false;
        state.seoTabSelected = false;
        switch (action.payload) {
          case BrandTabConstant.GENERAL:
            state.generalTabSelected = true;
            break;
          case BrandTabConstant.IMAGES:
            state.imagesTabSelected = true;
            break;
          case BrandTabConstant.SEO:
            state.seoTabSelected = true;
            break;
          default:
            break;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          // state.brandImgPath = action.payload.croppedImageUrl;
          state.brandImgPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleBannerFileUpload: {
      reducer(state, action) {
        if (action.payload !== undefined) {
          state.brandBannerPath = action.payload.croppedImageUrl;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtBrandName: {
      reducer(state, action) {
        state.txtBrandName = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleIsEnableBrand: {
      reducer(state, action) {
        if (state.IsEnableBrand) {
          state.IsEnableBrand = false;
        } else {
          state.IsEnableBrand = true;
        }
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
    handleTxtSeoUrl: {
      reducer(state, action) {
        state.txtSeoUrl = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaTitle: {
      reducer(state, action) {
        state.txtMetaTitle = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleMetaDesc: {
      reducer(state, action) {
        state.txtMetaDesc = action.payload;
      },
      prepare(e) {
        return {
          payload: e.target.value,
        };
      },
    },
    handleDeleteBrand: {
      reducer(state, action) {},
      prepare(e) {
        deleteBrand(e);
        return {
          payload: e,
        };
      },
    },
    handleResetBrand: {
      reducer(state, action) {
        state.selectedBrandId = 0;

        state.generalTabSelected = true;
        state.txtBrandName = "";
        state.IsEnableBrand = true;

        state.imagesTabSelected = false;
        state.brandImgPath = "/images/Default/product.png";
        state.brandBannerPath = "/images/Default/product.png";

        state.seoTabSelected = false;
        state.txtSeoUrl = "";
        state.txtMetaTitle = "";
        state.txtMetaDesc = "";
      },
      prepare(e) {
        return {
          payload: e,
        };
      },
    },
  },
  extraReducers: {
    [getAllBrandreq.fulfilled]: (state, action) => {
      if (action.payload.response !== false) {
        if (action.payload.brandid === 0) {
          state.allBrands = action.payload.response;
        } else {
          if (action.payload.response.length > 0) {
            state.selectedBrandId = action.payload.response[0].brandId;

            state.txtBrandName = action.payload.response[0].brandName;
            state.IsEnableBrand = action.payload.response[0].isActive;

            state.brandImgPath = action.payload.response[0].brandImgUrl;
            state.brandBannerPath = action.payload.response[0].bannerUrl;

            state.txtSeoUrl = action.payload.response[0].seoUrl;
            state.txtMetaTitle = action.payload.response[0].metaTitle;
            state.txtMetaDesc = action.payload.response[0].metaDesc;
          }
        }
      }
    },
    [updateBrandReq.fulfilled]: (state, action) => {
      if (action.payload !== false) {
        state.selectedBrandId = action.payload;
      }
    },
  },
});

export const {
  handleTabSelection,
  handleFileUpload,
  handleBannerFileUpload,
  handleIsEnableBrand,
  handleTxtBrandName,
  handleTxtSeoUrl,
  handleMetaTitle,
  handleMetaDesc,
  handleResetBrand,
  handleDeleteBrand,
} = BrandSlice.actions;

export default BrandSlice.reducer;
