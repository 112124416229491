import React from "react";
import { connect } from "react-redux";
import { decodeTokenReq, handleMyCartItems, handleUpdateIsLoginStatus } from "./MainSlice";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import ClientMainLayout from "../../Layout/ClientLayout/ClientMainLayout";

const MainLayoutContainer = ({ decodeTokenReq, isLogin, handleUpdateIsLoginStatus, ...props }) => {
  React.useEffect(() => {
    decodeTokenReq();
  }, [decodeTokenReq]);

  return <ClientMainLayout isLogin={isLogin} handleUpdateIsLoginStatus={handleUpdateIsLoginStatus} {...props} />;
};

const selectUserMainDetails = (state) => state.clientMain.userMainDetails;
const selectCartItems = (state) => state.clientMain.cartItems;
const selectIsLogin = (state) => state.clientMain.isLogin;

const mapStateToProps = (state) => ({
  userMainDetails: selectUserMainDetails(state),
  cartItems: selectCartItems(state),
  isLogin: selectIsLogin(state),
});

const mapDispatchToProps = {
  decodeTokenReq,
  handleUpdateIsLoginStatus,
  handleMyCartItems,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayoutContainer));
