import axios from "axios";
import config from "../config/server-config";
import jwt from "jsonwebtoken";
import { history } from "../utils/navigation-util";

export const TokenDecode = async () => {
  try {
    const token = localStorage.getItem("token");
    jwt.verify(token, config.tokenSecrectKey, (err, user) => {
      if (err) {
        window.localStorage.clear();
      }
    });
    return await jwt.decode(token);
  } catch (e) {
    window.localStorage.clear();
  }
};

export const GetAllImages = async (imageType) => {
  try {
    let response = await axios.post(config.getAllImages, {
      ImageType: parseInt(imageType),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GetAllPDF = async () => {
  try {
    let response = await axios.post(config.getAllPdfs, {});
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
